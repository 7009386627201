var Maje = Maje || {};
var Common = Common || {};

Common.validator = Common.validator || {};

Maje.header = Maje.header || {};

Maje.home = Maje.home || {};

Maje.footer = Maje.footer || {};

Maje.product = Maje.product || {};

Maje.minicart = Maje.minicart || {};

Maje.cart = Maje.cart || {};

Maje.account = Maje.account || {};

Maje.checkoutminicart = Maje.checkoutminicart || {};

Maje.checkout = Maje.checkout || {};

Maje.confirmation = Maje.confirmation || {};

Maje.zoomProduct = Maje.zoomProduct || {};

Maje.lookbook = Maje.lookbook || {};

Maje.giftpage = Maje.giftpage || {};

Maje.login = Maje.login || {};

Maje.util = Maje.util || {};

Maje.einstein = Maje.einstein || {};

/*
*	Bind events for the lookbook
*/
Maje.lookbook.bindEvents = function() {

	Maje.lookbook.initSliderLooks();
	Maje.lookbook.toggleProductSize.events();
	Maje.lookbook.video();

	// init event for add to cart
	app.product.initAddToCart();
}

/*
*	Init slider swiper
*/
Maje.lookbook.initSliderLooks = function() {

	function extractUrlParams(param){
		var vars = {};
		window.location.href.replace(
			/[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
			function( m, key, value ) { // callback
				vars[key] = value !== undefined ? value : '';
			}
		);

		if ( param ) {
			return vars[param] ? vars[param] : null;
		}
		return vars;
	}

	var redImg = 0;
	var nbLook = $(".lookbook_E16").length - 1;
	var slideIndex = extractUrlParams("index");

	if(slideIndex == null || slideIndex > nbLook){
		slideIndex = 0;
	}

	// Check the swiper exist
	if($('.swiper-container').length) {

		// Init slider
		var mySwiperLookbook = new Swiper('.swiper-container', {
			mode:'horizontal',
			loop: true,
			grabCursor: false,
			simulateTouch: false,
			initialSlide: slideIndex,
			onSlideChangeEnd: function(swiper){

				var allVid = $('.content-right iframe').length;
				if (allVid > 0){
					for(var i=0; i<allVid; i++ ){
						var iframe = $('.content-right iframe')[i];
						var player = $f(iframe);
						player.api('pause');
					}

					(function(){
						var iframe = $('.swiper-slide-visible.swiper-slide-active .content-right iframe')[0];
						var player = $f(iframe);
						player.api('play');
					})()
				}

				if (redImg >= 5 && $(".redlookbook").length > 0){
					redImg = 0;
					$(".swiper-slide-visible.swiper-slide-active .redlookbook").animate({"opacity" : 0}, 3250);
				}
			},
			onSlideChangeStart: function(swiper){
				redImg++
				$(".redlookbook").css({"opacity" : 0});
				if (redImg >= 5 && $(".redlookbook").length > 0){
					$(".swiper-slide-visible.swiper-slide-active .redlookbook").css({"opacity" : 1});
				}
			}
		});

		// Click on left : show the previous slide
		// Click on right : show the next slide
		$('.swiper-wrapper .swiper-button-prev').on("click", function(e) {
			e.preventDefault();
			mySwiperLookbook.slidePrev();
		});

		$('.swiper-wrapper .swiper-button-next').on("click", function(e) {
			e.preventDefault();
			mySwiperLookbook.slideNext();
		});

	}

}

/*
 *	Init jwplayer and load
 */
Maje.lookbook.video = function(){

	// Get the url of the video
	var url_video = $("div#urlVideo").data("src-video");

	// Check url exist
	if(url_video) {

		// Init player
		jwplayer("video").setup({
			file: url_video,
			width: "100%",
			height: "100%",
			autostart: false,
			mute: false,
			controls: true,
			repeat:false
		});

	}
}

/*
*	Events for toggle the product sizes to look
*/

Maje.product.eventLookComplet = function() {

	// Init form
	$(document).on('click', 'a.btnSelectedSizes', function() {
		$(this).closest('div.complete-look-variations').toggleClass('active');
	});

	// Event to select size
	$(document).on('click', '.complete-look-variations form .size-list ul li a.size', function(e) {
		e.preventDefault();
		var form = $(this).closest('form');
		var liParent = $(this).parent();
		// we get text of element selected
		var valSelected = $(this).text();

		// we define a variable for find the html and apply the value selected on it
		var valSelectedDefine = $(this).closest('.complete-look-variations').find('.valueSizeSelected');
		valSelectedDefine.text(valSelected);
		// when you click on div complete look variation you can show or hide with class active,
		// this class active is define on css for show hide some element
		$(this).closest('div.complete-look-variations').addClass('selectedSize').toggleClass('active');

		form.find('li.selected').removeClass('selected');
		form.find('input#pid').val('');

		if(!liParent.hasClass('size-unvailable')) {
			liParent.addClass('selected');
			form.find('input#pid').val(liParent.data('productid'));
			form.find('span.error-select-size').remove();
			// form.find('button').removeClass('disabled');
		} else {
			// form.find('button').addClass('disabled');
		}

		form.trigger('submit');
	}).on('click.sz', 'button.toggle-size', function(e) {
		e.preventDefault();
		$('li.size-active').removeClass('size-active');
		$(this).closest('li').addClass('size-active');
	}).on('click.sz', 'body', function(e) {
		if ( !$(e.target).is('li.size-active') && !$(e.target).closest('li.size-active').length ) {
			$('li.size-active').removeClass('size-active');
		}
	});

	$(document).on('submit', '.complete-look-variations form', function(e) {
		e.preventDefault();
		if($(this).find('input#pid').val() !== "") {
			var form = $(this).closest("form");
			var data = form.serialize();

			app.cart.update(data, function (response) {

				app.minicart.show(response);
				Maje.minicart.bindEvents();

				$('#mobileBasket span').html($('#mini-cart .mini-cart-nb').html());
				$('.footerMobile .mini-cart-total span.mini-cart-nb').html($('#mini-cart .mini-cart-nb').html());
			});
		} else {
			$(this).find('.error-select-size').remove();
			$(this).find('button').after('<span class="error-select-size" style="display:block;color:red">'+ $(this).find('button').data('error') +'</span>');
		}
	});
}

Maje.lookbook.toggleProductSize = (function() {

	var _init = function(look) {
		look.find("ul.size li").removeClass("selected");
		look.find("input#pid").val("");
	};

	var _selectedVariant = function(li) {
		var look = li.closest(".look");
		var form = look.find("form");
		var button = form.find("a");

		if (li.hasClass("notavailable")) {
			button.switchClass("add-to-cart", "coming-soon");
			button.text(app.resources.COMMING_SOON);
		} else {
			li.addClass("selected");
			button.switchClass("coming-soon", "add-to-cart");
			button.text(app.resources.ADD_TO_CART);
			form.find("input[id='pid']").val(li.data("variant"));
		}
	};

	var events = function() {
		$("div.look h3").on("click", function() {
			var look = $(this).closest(".look");
			var listLook = $(this).closest(".list-look")
			var li = look.find("li:first");

			if (!look.hasClass("expand")) {
				listLook.find(".look").removeClass("expand");
			}

			look.toggleClass("expand");

			_init(look);

			if (look.hasClass("expand")) {
				_selectedVariant(li);
			}
		});

		$("div.look div.product ul.size li a").on("click", function() {
			var look = $(this).closest(".look");
			var li = $(this).closest("li");

			_init(look);
			_selectedVariant(li);
		});

		$(".list-look").each(function() {
			var look = $(this).find(".look");

			if (look.length == 1) {
				look.addClass("noPicto");
				look.find("h3").trigger("click");
				look.find("h3").off("click");
			}
		});
	};

	return {
		events : events
	};

})();

/*
*	Show the popup with message of validation after add to cart a product
*/
Maje.lookbook.showPopupValideAddToCart = function(){

	//	Init the popup
	$("#popup-message-valideAddToCart").dialog({
		closeText: "",
		draggable: false,
		dialogClass : 'message-valideaddtocart-dialog',
		height: "auto",
		width: "450",
		modal: true,
		position: "center",
		close: function() {
			$(this).addClass("hidden");
		}
	});
}

Maje.minicart.bindEvents = function(){
	Maje.minicart.initRemoveButtons();
	$('.alertunavalability').on('click', function(e) {
		e.preventDefault();
		e.stopPropagation();
		// Get the clicked row
		var cartRow = $(this).closest('.cart-row');

		// Hide Alert & Remove buttons
		$(this).css('display', 'none');
		cartRow.find('.unavail-remove').css('display', 'none');

		cartRow.parent().find('.alert-product-unavailable').css('display', 'block');
		cartRow.parent().find('.alert-product-unavailable .unavail-email').focus();
	});


	$('.alertunavalability-valid').on('click', function(e) {
		e.preventDefault();
		var email = $(this).parent().find('.unavail-email').val();
		var url = $(this).parent().find('.alertunavalability-valid').attr('href');
		if(url.length > 0) {
			url += '&email=' + email;
		}

		// Find the div in which the success message will be display.
		var divMessage = $(this).closest('.item-list').find('.attribute');
		var message = $('<span class="productalert-result"></span>');
		var parentCont = $(this).closest('.item-list.unavailable-pdt');

		if(!$(this).parent().find('.unavail-email').valid() || $(this).parent().find('.unavail-email').val().length == 0) return;
		$.ajax({
			url : url,
			success : function (response) {
				if (response.success == true) {
					if (divMessage.length) {
						message.text(response.message);
						message.addClass('success');
						divMessage.append(message);
						parentCont.addClass('msgAdd');
					}
				} else {
					message.text('Une erreur est survenue');
					message.addClass('error');
					divMessage.append(message);
				}
			},

			error : function () {
				// ToDo : Handle an error
				alert('error');
			}
		});

		$(this).parent().css('display', 'none');
		var cartRow = $(this).closest('.item-list').find('.cart-row');
		cartRow.find('.alertunavalability').css('display', 'block');
		cartRow.find('.unavail-remove').css('display', 'block');

	});

	$('.alertunavalability-cancel').on('click', function(e) {
		e.preventDefault();
		$(this).parent().css('display', 'none');
		var cartRow = $(this).closest('.item-list').find('.cart-row');
		cartRow.find('.alertunavalability').css('display', 'block');
		cartRow.find('.unavail-remove').css('display', 'block');
	});


	// add coupon event
	var couponEl = $('.cart-coupon-code.coupon-ajax'),
		input = couponEl.find('input'),
		errorEl = couponEl.find('.coupon-error, span.error-message'),
		crmCouponSelectBox = $("#mini-cart .crm_coupon"),
		crmCouponValue = $("#mini-cart .hidden-crm-coupon"),
		crmCouponSubmit = $("#mini-cart #button-apply-crm-coupon");
	couponEl.on('click', 'button', function(e){
		e.preventDefault();
		errorEl.empty();

		var val = input.val();

		if (val.length===0) {
			errorEl.text(app.resources.COUPON_CODE_MISSING);
			return;
		}

		var url = app.util.appendParamsToUrl(app.urls.addCoupon, {
				couponCode: val,
				format: 'ajax'
			});

		$.getJSON(url, function(data) {
			var fail = false,
				msg = '';

			if (!data) {
				msg = app.resources.BAD_RESPONSE;
				fail = true;
			} else if (!data.success) {
				msg = data.message;
				fail = true;
			}

			if (fail) {
				errorEl.text(msg);
				return;
			}

			errorEl.empty();
			if( !couponEl.hasClass('mini-cart-coupon') ) {
				location.reload();
				return;
			}
			$.ajax({
				url: app.urls.minicartView,
				success: function(response) {
					app.minicart.show(response);
					Maje.minicart.bindEvents();
				}
			});

		});
	});


	crmCouponSelectBox.on('change', function() {
		var couponVal = $(this).val();
		crmCouponValue.val(couponVal);
	});
	crmCouponSubmit.off('.crmcoupon').on('click.crmcoupon', function() {
		var couponVal = crmCouponValue.val();
		if (couponVal != '') {
			$(this).prop('disabled',true);
			$.ajax({
				url: app.urls.addCRMCoupon,
				cache:false,
				data: { couponCode: couponVal },
				type : "POST",
				dataType : "json",
				success: function(response) {
					var msg = '';
					var fail = false;
					if (response.response_code === 'FAILED') {
						msg = app.resources.BAD_RESPONSE;
						fail = true;
					}

					if (fail) {
						errorEl.text(app.resources[response.response_message]);
						return;
					}
					errorEl.empty();

					$.ajax({
						url: app.urls.minicartView,
						cache:false,
						success: function(response) {
							app.minicart.show(response);
							// display coupon inform message
							$('#mini-cart').find('.coupon-error').text(msg).addClass('coupon-inform');
							Maje.minicart.bindEvents();
						}
					});
				}
			});
		}
	});

}

Maje.checkout.bindEvents = function(){
	Maje.checkout.customValidation();
	Maje.checkout.addressInitForm();
	Maje.checkout.shippingAddressSelect();
	Maje.checkout.surchageCreditCardErrorMessage();
	//Maje.checkout.onChangePaymentMethod();
	Maje.checkout.shieldClicksFinalOrderButton();
	//Maje.checkout.paypalViaOgone();
	Maje.checkout.checkCreditcardExpiration();
	Maje.checkout.collapsibleBillingAddress();
	Maje.checkout.validatePickupStore();
	Maje.checkout.selectPickupStore();
	Maje.checkout.showLastPickupStoreOnMap();
	Maje.checkout.collapscartTotal();
	Maje.checkout.updateMapSize();
	Maje.checkout.updateEventsCreditCard();
	Maje.checkout.giftMessage();
	//Maje.checkout.predictiveAddressPosition();
	Maje.cart.initSwitchSize();

	if ($("[name$='_shippingMethodID']:checked").val() == "pointrelais") {
		Maje.checkout.contribCrosslog();
	}
	Maje.checkout.couponHandler();
}

/*** Google predictive address container issue ***/
Maje.checkout.predictiveAddressPosition = function() {
	$(document).on('keypress', '#searchPlcVal', function(event){
		$('.pac-container').appendTo('.location-search.form-row');
	});
}

/*
 * When editing the billing address form we must check the billing address is different then the shipping address.
 */
Maje.checkout.editBillingAddress = function(){

	var urlparts = window.location.href.split('?');
	if(urlparts.length >= 2) {
		var params = app.util.getQueryStringParams(urlparts[1]);
		if(params.editBillingForm === "true"){
			$('input[name*=useAsBillingAddress]').attr("checked" , false).trigger("click");
			$("html, body").animate({ scrollTop: $(document).height()});
			$('input[name*=useAsBillingAddress]').attr("checked" , false);
		}
	}
}

/*
 * Update the events of credit card in billing
 */
Maje.checkout.updateEventsCreditCard = function(){

	Maje.checkout.hideShowBlockCreditCardSave(false);

	//hide show credit card form
	//hide credit card form if current user have selected card
	if ($("#saved-credit-card-container").length > 0) {
		$("#ccard-type-container").hide();
		$("div[data-method='CREDIT_CARD']").hide();
	}


	//Hide block add a new payment option and update the cvn displayed
	$("div.billing-credit-card-save input.radio-creditcard").on("click", function(){
		$(this).attr("checked", true);
		$("#ccard-type-container .radio-creditcard").prop('checked', false);
		$('.billing-payment-option .payment-method-expanded').removeClass("payment-method-expanded");
		Maje.checkout.hideShowBlockCreditCardSave(false);
	});

	//Display block add a new payment option and update the events of credit card save
	$("div.payment .billing-add-payment-options").on("click", function(){
		if ($(this).is(':checked')) {
			//init all input value and checked
			$("#ccard-type-container").show();
			$("div[data-method='CREDIT_CARD']").show();
			if ($("#saved-credit-card-container").length > 0) {
				$(".select-creditCards").hide();

			}

			$("form :input[type='text']").val("");
			$("form :input[type='tel']").val("");
			$("form :input[type='radio']").removeAttr("checked");
			$('body, html').animate({scrollTop : 0});
			$(".radio-creditcard:first").attr('checked', true).trigger("click");

			//when authenticated user click add new payment method, the first method must be selected by default
			Maje.checkout.hideShowBlockCreditCardSave(false);
		} else {
			$("#ccard-type-container .radio-creditcard").prop('checked', false);
			$('.billing-payment-option .payment-method-expanded').removeClass("payment-method-expanded");

			$("#ccard-type-container").hide();
			$("div[data-method='CREDIT_CARD']").hide();
			if ($("#saved-credit-card-container").length > 0) {
				$(".select-creditCards").show();

			}
			Maje.checkout.hideShowBlockCreditCardSave(false);
		}
	});

	$(document).on('click', '.cvn .big-tooltip', function(){
		$('.checkout-left-part > .helper-text').toggleClass('showMe');
	})

	$(document).on('click', '.checkout-left-part .showMe', function(){
		$(this).removeClass('showMe');
	})
}

/*
 * First functionality : Update the show or hide for the cvn of credit card save
 * Second functionality : Update the cvn in function of input radio selected
 */
Maje.checkout.hideShowBlockCreditCardSave = function(submitCheckout){
	$("div.billing-credit-card-save input.radio-creditcard").each(function(){
		var divcvn = $(this).closest("div.billing-credit-card-save").children("label.block-cvn");

		if($(this).is(":checked")){

			if($("div.form-row.cvn.required").hasClass("error")){
				divcvn.append($("div.form-row.cvn.required").children("span.error-message"));
			}

			divcvn.show();

			if(submitCheckout){
				$("#dwfrm_billing_paymentMethods_creditCard_cvn").val(divcvn.children("input").val());
			}
		}
		else{
			divcvn.hide();
		}
	});


}

Maje.checkout.contribCrosslog = function() {
	var currentAddress = encodeURIComponent(app.constants.CROSSLOG_DEFAULT_ADDRESS);

	function configureIframe() {
		if ($('.crosslog').data('location') != ''){
			currentAddress = $('.crosslog').data('location');
		}

		var params = {
			"UI":  app.constants.DEFAULTLLANGUAGECODE,
			"COUNTRY":  app.constants.DEFAULTCOUNTRYCODE,
			"QUERY": currentAddress,
			"WIDTH": 800,
			"ID_CLIENT" : app.constants.CROSSLOG_CLIENT_ID,
			"ID_COMMANDE" : app.constants.CROSSLOG_COMMANDE_ID
		};

		var customAddress = $('.crosslog-address').val();

		if (customAddress) {
			params.QUERY = customAddress;
		}

		if ($(window).width() < 1025) {
			params.MOBILE = 1;
			params.WIDTH = 600;
			$('.crosslog').attr('height' ,'320');
		} else {
			$('.crosslog').attr('height' ,'530');
			params.FORCEWEB = 1;
		}

		var iframeUrl = app.constants.CROSSLOG_IFRAME_URL + "?";
		for(var param in params) {
			iframeUrl += param + "=" + params[param] + "&";
		}

		$('.crosslog').attr('width', '100%');
		$('.crosslog').attr('src', iframeUrl);
	}


	var submitHandler = function(event) {
		var firstName = $(".crosslogForm .billingFirstName").val();
		var lastName = $(".crosslogForm .billingLastName").val();
		var email = $(".crosslogForm .billingEmail").val();

		if (firstName && lastName &&
			$("[name$='_shippingMethodID']:checked").val() == "pointrelais") {
			event.preventDefault();
			var currentTarget = event.currentTarget;
			var addr = {
				first_name: null,
				last_name: null,
				email: null,
				salutation: null,
				address1: null,
				country: null,
				city: null,
				zip: null
			};

			var errorMessage = $('.form-global-error').first();
			errorMessage.hide();
			$.ajax({
				url: app.urls.crosslog,
				method: "GET",
				contentType: 'text/xml',
			}).then(function (data) {
				if(!data) {
					errorMessage.text(app.resources.CROSSLOG_ERROR_MESSAGE);
					errorMessage.show();
					$("html, body").animate({ scrollTop: 0 }, 1000);
					return;
				}

				var parsedAddr = $.parseXML(data);

				addr = {
					first_name: firstName,
					last_name: lastName,
					email: email,
					salutation: $(".crosslogForm .billingSalutation").val(),
					phone: $(".crosslogForm .shippingPhone").val(),
					code: parsedAddr.getElementsByTagName("Code")[0].innerHTML,
					name: parsedAddr.getElementsByTagName("Name")[0].innerHTML,
					address1: parsedAddr.getElementsByTagName("Address1")[0].innerHTML,
					address2: parsedAddr.getElementsByTagName("Address2")[0].innerHTML,
					country: parsedAddr.getElementsByTagName("CountryCode")[0].innerHTML,
					city: parsedAddr.getElementsByTagName("City")[0].innerHTML,
					zip: parsedAddr.getElementsByTagName("PostalCode")[0].innerHTML
				}

				if (addr.first_name && addr.last_name &&
					addr.address1 && addr.country && addr.city && addr.zip) {
					$(".crosslogForm .billingPhone").val(addr.phone);
					$(".crosslogForm .shippingFirstName").val(addr.first_name);
					$(".crosslogForm .shippingLastName").val(addr.last_name);
					$(".crosslogForm .shippingAddress1").val(addr.address1);
					$(".crosslogForm .shippingMessage").val(addr.address2);
					$(".crosslogForm .shippingCountry").val(addr.country);
					$(".crosslogForm .shippingCity").val(addr.city);
					$(".crosslogForm .shippingZip").val(addr.zip);
					$(".crosslogForm .shippingRelayID").val(addr.code);
					$(".crosslogForm .shippingRelayName").val(addr.name);
				}
			}).then(function(){
				if (addr.first_name && addr.last_name &&
					addr.address1 && addr.country && addr.city && addr.zip) {
					$('form.checkout-shipping').unbind('submit', submitHandler);
					$('form.checkout-shipping button[type="submit"]').click();
				}
			});
		}
	}

	$('form.checkout-shipping').bind('submit', submitHandler);
	$('.crosslog-address-send').bind('click', configureIframe);

	$('.crosslog-address').keypress(function (e) {
		// check if the key pressed is Enter
		if(e.which == 13) {
			$('.crosslog-address-send').click();
			return false;
		}
	});

	configureIframe();

}

Maje.checkout.customValidation = function() {

    var checkoutFormSettings = $.extend(true,
        app.validator.settings,
        {
            invalidHandler : function(event, validator) {
                for(err in validator.errorList) {
                    if($(".shipping-fields input").is(validator.errorList[err].element)) {
                        // If one of the current field in error is one of the input fields inside the shipping-fields area
                        // Slide the form to reveal the fields
                        $("div.shipping-fields").slideDown()
                        break;
                    }
                }
                var errors = validator.numberOfInvalids();
                if (errors) {
                    var message;
                    if(errors > 1) {
                        message = $(this).data('errorsmessage') || "Please check the following fields";
                    } else {
                        message = $(this).data('errormessage') || "Please check the following field";
                    }

                    var errorMessage = $('.form-global-error').first();
                    // Set the text message
                    errorMessage.text(message);

                    // Move element and scroll to top page
                    $(this).toggleClass('global-error');
                    $('window').scrollTop();
                }
            }
        }

    );

    var validator = $('form.checkout-shipping').validate(checkoutFormSettings);
}

Maje.checkout.shippingAddressSelect = function() {

    function populateAddressFields(aid) {
        // For convenience, we use what's already there in app.js, simulating a change on the hidden select box
        $('select[name*=addressList]').val(aid).change();
		$('input[name$=selectedAddressID]').val(aid);
		app.prefixformat.init();
    }

    $('div#primary').on('click', 'a.shipping-address-add, a.address-edit', function(e) {
		e.preventDefault();
		e.stopPropagation(); // prevent clicks on (a.address-edit) from reaching (label.address-details)

        var hash = $(this).prop("hash"); // get the anchro name of the link
        $('div.shipping-fields').slideDown(400, function(){
			location.hash = hash;
			$('html, body').scrollTop($('.shipping-fields').offset().top - $('#header').height());
		});
        $('div.shipping-form input[name=addressbook]:checked').removeAttr('checked');

        if($(this).hasClass('shipping-address-add')) {
            // Adding new address, reset validator and fields
            $('form.checkout-shipping').validate().resetForm();
            $('div.shipping-fields').find('input:text, input[type=tel]').val("");
            $('div.shipping-fields input:radio[name$=salutation]').removeAttr('checked');
            $('input[name$=selectedAddressID]').val('');
			$('.capSaisieFieldQualityCode').val(60);
            $('div.addtobook').show();
        } else {
            $('div.radioAdressCont').removeClass('active');
            $(this).closest('div.radioAdressCont').addClass('active');
            populateAddressFields($(this).closest('label').data('aid'));
            $('div.addtobook').hide();
        }
		app.prefixformat.init();
    });

    $('div#primary').on('click', 'div.select-address label.address-details', function() {
        var aid = $(this).data('aid');
        $('div.shipping-fields').slideUp().promise().done(function() {
            // promise().done() makes sure the following fires only when the animation is done.
            populateAddressFields(aid);
            $('input[name$=selectedAddressID]').val(aid);
        });
    });

    if ($('div.shipping-fields span.error-message').length > 0) {
        $('div.shipping-fields').show();
    }
}

Maje.checkout.addressInitForm = function() {
	$(document).on("change IEchange", ".shipping-form .shipping-fields select[id$='_country']", function(){
		$(window).trigger('checkout.load.provinces',{country: this});
	});
	$(".shipping-form .shipping-fields select[id$='_country']").trigger('change');
	$(document).on("change IEchange", ".billing_fields select[id$='_country']", function(){
		$(window).trigger('checkout.load.billing.provinces',{country: this});
	});
	$(".billing_fields select[id$='_country']").trigger('change');
}

Maje.checkout.updateMapSize = function() {

	var btnListMap = $('#listCmd'),
	storeList = $('#storeList');

	btnListMap.on('click', function(event){
		event.preventDefault();
		storeList.fadeToggle();
		$(this).parent().toggleClass('show');
		/*storeList.jScrollPane({
			autoReinitialise: true,
			mouseWheelSpeed : 20
		});
		var api = $('#storeList').data('jsp');
		if ($('.store.highlighted').length){
			api.scrollToElement($('.store.highlighted'));
		}*/
	});

	$('#moreStore').on('click', function(event){
		event.preventDefault();
		storeList.toggleClass('open');
		$(this).siblings('.listLocator').toggleClass('open');
		$(this).toggleClass('open');
	});

}


Maje.checkout.checkCreditcardExpiration = function() {
	var currentExpirationDate = new Date();
	if($(".month-select select").val() == ""){
		var expirationMonth = currentExpirationDate.getMonth() + 1;
		$(".month-select select").val(expirationMonth);
	}
	if($(".year-select select").val() == ""){
		var expirationYear = currentExpirationDate.getFullYear();
		$(".year-select select").val(expirationYear);
	}
}
/**
 * Load all events related to PayPal payment
 */
Maje.checkout.paypalViaOgone = function() {
	$(".radio-creditcard").click(function() {
		var val = $(this).attr('id');
		var selectCreditCardsRadio = $(".select-creditCards :input[type='radio']");
		if(selectCreditCardsRadio.length > 0){
			//on the case when the user switch from saved credit card to list of credit card (add new card)
			// we init the billing form (init all the input)
			if($(".select-creditCards input.radio-creditcard:checked").length > 0){
				$('#PaymentMethod_CREDIT_CARD :input').not('.saveCard').val("");
			}
			selectCreditCardsRadio.removeAttr("checked");
			Maje.checkout.hideShowBlockCreditCardSave(false);
		}

		if(val && val.indexOf("paypal") >= 0) {
			$(".selected-payment-method").val($("#paypal").data("js-paypal-value"));
			$("#saved-credit-card-container").hide();
			Maje.checkout.changePaymentMethodForm($("#paypal").data("js-paypal-value"))
		} else if(val && val.indexOf("sofort") >= 0) {
			$(".selected-payment-method").val($("#sofort").data("js-sofort-value"));
			$("#saved-credit-card-container").hide();
			Maje.checkout.changePaymentMethodForm($("#sofort").data("js-sofort-value"))
		} else if(val && val.indexOf("ccard") >= 0) {
			$(".selected-payment-method").val($(".selected-payment-method").data("js-ccard-value"));
			$("#saved-credit-card-container").show();
			Maje.checkout.checkCreditcardExpiration();

			Maje.checkout.changePaymentMethodForm($(".js-ccard-payment-method").data("js-ccard-value"));
			if (val.indexOf("amex") >= 0) {
				$('input[id$=creditCard_number]').attr('maxlength', 15);
			} else {
				$('input[id$=creditCard_number]').attr('maxlength', 16);
			}
		}
		$('span.payment-choose-method').hide();
	});
}

Maje.checkout.collapsibleBillingAddress = function() {
	// Initialization of the billing fieldset
	if ($('input[name*=useAsBillingAddress]:checked').is(':checked') == true) {
		$('fieldset.billing_fields').hide();
	} else {
		$('fieldset.billing_fields').show();
	}

	$('.checkbox-shipping').on('click', 'input[name*=useAsBillingAddress]', function () {
		if ($('input[name*=useAsBillingAddress]:checked').is(':checked') == true) {
			$('fieldset.billing_fields').slideUp();
		} else {
			$('fieldset.billing_fields').slideDown();
		}
	});
}

Maje.checkout.collapscartTotal = function () {
	//only on mobile collapse

		$(document).on('click', '.miniSummaryCont .section-header', function(e){
			if($(window).width() <= 767) {
				$(this).parent().toggleClass('showIt');
			}
		});


}

Maje.checkout.validatePickupStore = function() {
	$('form.checkout-shipping button#continue').click(function() {
		/* If an avatax error occured */
		if($("#estimation-tax-value.error").length > 0) {
			$('html, body').animate({
				scrollTop: $("#estimation-tax-value").position().top
			}, 800);
			return false;
		}
	});
}

Maje.checkout.selectPickupStore = function() {
	$('.pickup-store-selection').on('click', 'a.btnSelectStore, a.selectLastPickupStore', function(e) {
		e.preventDefault();
		Maje.checkout.updateStoreButtons($(this).data("storeid"));
		if (!$('.pickup-store-selection.show-shop-details').length) {
			$('.pickup-store-selection').addClass('show-shop-details');
		}
		var windwVar = $(window),
			selectedStoreList = $('.listLocator'),
			heightToSet = windwVar.innerHeight() - 150,
			listLocator = selectedStoreList.offset().top + 150,
			viewportDim = windwVar.scrollTop() + windwVar.innerHeight();
		if (viewportDim < listLocator) {
			$('html, body').animate({
				scrollTop: selectedStoreList.offset().top - heightToSet
			}, 400);
		}
		$(this).addClass('shopchecked');
		var storeID = '#store-'+$(this).data('storeid');
		var storeListing = $('#storeList .store');
		if ($(storeID).length){
			storeListing.removeClass('selectedStore');
			$(storeID).addClass('selectedStore');
		}
	});
}

Maje.checkout.updateStoreButtons = function(storeID) {
	$('.pickup-store-selection').find('a.btnSelectStore, a.selectLastPickupStore')
									.text(app.resources.SELECT_THIS_STORE)
									.filter('[data-storeid=' + storeID + ']')
										.text(app.resources.STORE_SELECTED_MAJE)
									.end()
								.end()
								.find('input[type=hidden][name$=_pickupStoreID]')
									.val(storeID);
	if ($('#panel .errorGeo').length){
		$('#panel .errorGeo').remove();
	}
}

Maje.checkout.showLastPickupStoreOnMap = function() {
	$('.pickup-store-selection').on('click', 'a.showStoreOnMap', function(e) {
		e.preventDefault();
		var storeID = $(this).data("storeid");
		Maje.storelocator.highlightStore(storeID);

		if($(window).width() <= 767) {
			$('html,body').animate({
				  scrollTop: $('a.modeMap').offset().top
			}, 'slow');

			$('a.modeMap').click();
		}
	});
}

/**
 * Change the current payment method block
 */
Maje.checkout.changePaymentMethodForm = function(paymentMethodToActivate) {
	$(".payment-method").each(function() {
		$(this).attr('class', $(this).attr('class').replace('payment-method-expanded', ''));
	});

	$("#PaymentMethod_" + paymentMethodToActivate).attr('class', $("#PaymentMethod_" + paymentMethodToActivate).attr('class') + ' ' + 'payment-method-expanded');
}

Maje.checkout.giftMessage = function() {
	var txtArea = $('.gift-message-text textarea');
	$("input[name$='_addGiftMessage']").change(function() {
		if(this.checked) {
			txtArea.prop('disabled', false);
		} else {
			txtArea.prop('disabled', true);
			$('.gift-message-text span.error-message').remove();
			txtArea.val('');
			txtArea.removeClass('error-message');
		}
	})
}
Maje.checkout.couponHandler = function() {
	$("body").on('click',"button[name*='coupon_addCoupon']", function(e) {
		e.preventDefault();
		var url = $("#CouponForm").attr("action");
		var couponFieldName = $(".js-add-coupon").attr("name");
		var couponFieldValue = $(".js-add-coupon").val();
		var submitFieldName = $(this).val();
		var estimationShippingCostValue = $("#estimation-shipping-cost").val();
		var estimationOrderSubTotalValue = $("#estimation-order-subtotal").val();

		var estimationShippingCost = isNaN(estimationShippingCostValue) ? 0 : estimationShippingCostValue;
		var estimationOrderSubTotal = isNaN(estimationOrderSubTotalValue) ? 0 : estimationOrderSubTotalValue;
		var checkoutPage = (typeof $("#checkoutpage").val() !== 'undefined') ? $("#checkoutpage").val() : '';

		var data = {};
		data[couponFieldName] = couponFieldValue;
		data[submitFieldName] = submitFieldName;
		data['estimationShippingCost'] = estimationShippingCost;
		data['estimationSubtotal'] = estimationOrderSubTotal;
		data['checkoutpage'] = checkoutPage;
		data['csrf_token'] = $($("#CouponForm").find("input[name='csrf_token']")).val();

		app.ajax.post({
			url: url,
			data: data,
			target: ".summary",
			callback: function() {

			}
		});
	});

	$("body").on('click',".btn-couponremove", function(e) {
		e.preventDefault();
		var url = $("#CouponFormList").attr("action");

		var submitFieldName = $(this).attr("name");
		var submitFieldValue = $(this).val();
		var data = {};

		var estimationShippingCostValue = $("#estimation-shipping-cost").val();
		var estimationOrderSubTotalValue = $("#estimation-order-subtotal").val();

		var estimationShippingCost = isNaN(estimationShippingCostValue) ? 0 : estimationShippingCostValue;
		var estimationOrderSubTotal = isNaN(estimationOrderSubTotalValue) ? 0 : estimationOrderSubTotalValue;
		var checkoutPage = (typeof $("#checkoutpage").val() !== 'undefined') ? $("#checkoutpage").val() : '';

		data[submitFieldName] = submitFieldValue;
		data['estimationShippingCost'] = estimationShippingCost;
		data['estimationSubtotal'] = estimationOrderSubTotal;
		data['checkoutpage'] = checkoutPage;
		data['csrf_token'] = $($("#CouponFormList").find("input[name='csrf_token']")).val();
		app.ajax.post({
			url: url,
			data: data,
			target: ".summary",
			callback: function() {

			}
		});
	});

	$("body").on("change",".miniSummaryCont .crm_coupon", function() {
		var couponCode = $(this).val();
		$(".miniSummaryCont .hidden-crm-coupon").val(couponCode);
	});
	$("body").off('.crmcoupon').on("click.crmcoupon",".miniSummaryCont #button-apply-crm-coupon", function() {
		var couponValue = $(".miniSummaryCont .hidden-crm-coupon").val();
		if (couponValue != '') {
			$.ajax({
				url: app.urls.addCRMCoupon,
				cache:false,
				data: { couponCode: couponValue },
				type : "POST",
				dataType : "json",
				success: function(response) {
					if (response.response_code === 'OK') {
						//update summary view again
						$.ajax({
							url: app.urls.summaryRefreshURL,
							cache:false,
							type : "GET",
							success: function(response) {
								$(".summary").html(response);
							}
						})
					} else {
						console.log(app.resources[response.response_message]);
					}
				}
			});
		}

	});


}
Maje.confirmation.bindEvents = function() {

	function showKnownGuestLogin() {
		$('div.confirmation-login-box').dialog({
			width: "535px",
			dialogClass: "guestpop",
			modal: true
		});
	}

	var delayPopin = $(window).width() < 767 ? app.constants.GUEST_REGISTER_POPIN_DELAY_MOBILE : app.constants.GUEST_REGISTER_POPIN_DELAY_DESKTOP;

	try {
		delayPopin = parseInt(delayPopin);

		// First, check if a known guest was detected
		if ($('div.confirmation-login-box').length > 0) {
			setTimeout(showKnownGuestLogin, delayPopin);
		}
	} catch(e) {}

	$('div.confirmation-guest-create-account button').click(function(e) {
		e.preventDefault();
		showGuestPopinCreateAccount();
	});

    $('div.confirmation-right-block a.guest-create-account').click(function(e) {
    	var email = $("#register-bloc-wrapper input[name$='customer_email']").val();
    	if (email != '' && typeof email !== 'undefined') {
    		window.location.href = app.urls.registerPage + "?email=" + email;
    	}
		/*Maje.util.showLoginPopup();
		if ($(window).width() < 767) {
			$('html, body').animate({ scrollTop: 100 }, "slow");
		}*/
	});

	$('#login_popin .popup-close-span').on('click', function(e){
		if($(window).width() < 1024){
			$(".cart-centred-container").fadeIn();
			popupContainer.fadeOut();
			return;
		}
	});


    $('div.confirmation-right-block #newsletterForm').submit(function(e) {
		e.preventDefault();

        if ($(this).valid()) {
            var email = encodeURIComponent($(this).find('input.nlemail').val());
            var country = encodeURIComponent($(this).find('select.nlcountry').val());
            var targetUrl = $(this).attr("action");
            Maje.footer.callAjaxNewsletter(targetUrl,email,country,$(this));
        }
	});

	$('div.confirmation-known-guest-login a.button').click(function(e) {
		e.preventDefault();
		showKnownGuestLogin();
    });

    $('.show-ship-details').on('click', function(event){
        $("html, body").animate({ scrollTop: 0}, 500);
	});
}

Maje.home.bindEvents = function() {
	Maje.home.initModalPopup();
	Maje.home.tplProductBadge();
	Maje.home.vimeoplayerjs();
};

Maje.home.tplProductBadge = function() {
	var tplProduct = $(".homepage-3products");
	var badgeTplProduct = $(".homepage-3products").find(".badge").length;

	if (badgeTplProduct > 0){
		tplProduct.addClass("wrapper-badge");
	}

	//Add specific class to prices when there's a promotion
	var productPrice = $(".homepage-3products .product-price");

	if(productPrice.length){
		productPrice.each(function(){
			if($(this).find(".percentage").length){
				$(this).addClass("product-pricing-promo");
			}
		});
	}
}

Maje.home.initModalPopup = function() {
	var delayDisplayPopup = $('#delayDisplayPopup').val() * 1000;
	var delayBeforeDisplayPopup =  $('#delayBeforeDisplayPopup').val() * 1000;
	var delayCookie = $('#delayCookie').val();
	var popupWrapper = $('.home-popup');
	var popupBox = $('.home-popup-box');
	var _this = this;

	if (popupBox.hasClass('home-popup-newsletter'))
		delayDisplayPopup = 24 * 3600 * 1000;

	if (popupWrapper.length > 0 ) {
		popupWrapper.delay(delayBeforeDisplayPopup).fadeIn("slow", function(){
			if(!popupBox.hasClass("home-popup-newsletter") && !popupBox.hasClass("home-popup-private-sale")){
				popupBox.css({
					top: $(document).scrollTop() + $(window).height() / 2 - popupBox.height() / 2
				});
			}
			$(".popup-close-span, .popup-bg, .popup-close-cta, #popup-close-cta").on("click", function() {
				popupWrapper.stop().fadeOut();
			});
			$(document).mouseup(function (e){

				var ui = $(e.target).parents(".ui-autocomplete");
				if (ui.length > 0) { return;}

				var myTarget = $(e.target).parents(".home-popup");

				if (!myTarget.length){
					popupWrapper.stop().fadeOut();
				}
			});

			//setTimeout fadeOut popup (time : delayDisplayPopup)
			var hidePopup = setTimeout(function(){
				popupWrapper.fadeOut();
				}, delayDisplayPopup);

			//if click on popup, clear the setTimeout fadeOut (hidePopup)
			popupWrapper.on("click", function() {
				clearTimeout(hidePopup);
			});

		});


		if($.cookie('popupLogin')){
			$.removeCookie('popupLogin', { path: '/' });
		}else{
			$.cookie('cookie-home-popup', 'OK', {expires : Number(delayCookie),path: '/'});
		}

	}
}

Maje.header.bindEvents = function(){
	// gestion du menu depliant dans le menu en mode responsive
	Maje.header.expandMenu('.subMain', '.titleMain', '.mainSubList', 'click');
	// gestion de l'evenement qui permet d'afficher le menu mobile ou non.
	Maje.header.menu();
	Maje.header.moveToFixe();

	Maje.header.loginAndRedirect();

	Maje.header.btnScroll();

	//Maje.header.heightMiniCart();

	Maje.header.desktopSearch();
	//Search bar menu on tablet and mobile devices
	Maje.header.mobileSearch();

	//Search bar menu on tablet and mobile devices
	Maje.header.customerServiceHandler();

	//Account login overlay for incorrect credentials
	Maje.header.accountOverlayHandler();

	Maje.header.topMessage();
}

Maje.header.topMessage = function(){
	$(document).on('click', '.headerMsg__close', function(e){
		e.preventDefault();
		$.cookie('hideTopMes', 1, { expires: 1, path: '/' });
		$('.header-message-content').remove();
	});
}

Maje.header.customerServiceHandler = function() {
	$("a.customer-service-link").on('click', function(evt) {
		evt.preventDefault();
		if ($(window).width() > 767) {
			window.location.href = this.href;

			// var popupURL = $(this).attr("href");
			// if(!popupURL || popupURL ===""){
			// 	return;
			// }

			// var popupContainer = $("<div class='customer-service-popup'></div>").appendTo('body');

			// app.ajax.load({
			// 	url : popupURL,
			// 	target : popupContainer,
			// 	callback: function () {

			// 		popupContainer.find("span.popup-bg, span.popup-close-span").on('click tap', function() {
			// 			popupContainer.remove();
			// 		});

			// 		if($(window).scrollTop() > 0){
			// 			$('body, html').animate({scrollTop : 0});
			// 		}
			// 	}
			// });
		} else {
			var phoneNumberLink = 'tel:'+$(this).find('.header-phone').text();
			window.location.href= phoneNumberLink;
		}
	});
}

Maje.header.desktopSearch = function() {
	var wrapSearch = $('.header-search');
	var iconSearch = $('.search-icon');
	var inputSearch = wrapSearch.find('#qA');

	inputSearch.on('focus', function() {
		wrapSearch.addClass('expand-search');
	});

	iconSearch.on('click', function() {
		wrapSearch.addClass('expand-search');
	});

	$(document).mouseup(function (e){
		var myTarget = $(e.target).parents('.header-search');

		if (!myTarget.length){
			wrapSearch.removeClass('expand-search');
		}
	});

	$('#header #qA').on('focus', function() {
		$('#header').addClass('qa-has-focus')
    });
    $('#header #qA').on('blur', function() {
		$('#header').removeClass('qa-has-focus')
    });
}

Maje.header.mobileSearch = function () {
	//items located in simplesearch.isml
	var searchForm = $('.header-search > form');
	var searchIcon = $('.searchbar-icon');
	var searchBox = $('.mobile-searchBox');
	var isOpen = false;

	//Display expanded search bar on tablets and mobile devices
	searchIcon.on("click", function() {
		if(window.innerWidth < 1024){
			if(isOpen == false) {
				searchBox.addClass('active');
				isOpen = true;
			}
			else {
				searchBox.removeClass('active');
				isOpen= false ;
			}
		}
	});

	//Verify that search input is not empty before submiting form
	searchForm.submit(function(event) {
		event.preventDefault();
		var searchInput = $('#q').val();

		if (searchInput !== '') {
			$(this).unbind('submit').submit();
		}
	});
}

Maje.header.btnScroll = function(){
	$(document).on('click tap', '.scroll-top', function(e) {
		e.preventDefault();

		if($(window).scrollTop() > 0){
			$('body, html').animate({scrollTop : 0});
			return false;
		}
	});
}

Maje.header.accountOverlayHandler = function(){
	$(document).on('click', '.user-login-content #connection', function(e) {
		e.preventDefault();
		var urlData = {
				format:"ajax"
			};

		var $form = $('.connectPopin');
		var url = app.util.appendParamsToUrl(app.urls.accountloginajax, urlData);

		$.ajax({
			url: url,
			type: $form.attr('method'),
			data: $form.serialize(),
			success: function(data) {
				if (data.success) {
					window.location = data.url;
				} else if($(data).find(".main-login-error").length > 0){
					$("ul.menu-utility-user").replaceWith(data);
					$(".user-login-content").show();
					$( ".user-login-content, .loginUserMaje" ).hover(function() {
						$('.user-login-content').show().addClass('isHover');
					}, function() {
						$('.user-login-content').hide().removeClass('isHover');
					});
					setTimeout(function(){
						if(!$(".user-login-content").hasClass('isHover')){
							$(".user-login-content").fadeOut();
						}
					}, 2000);
				} else {
					// It is not normal to get data not containing a login error back
					window.location.reload();
				}
			}
		});
	});
}

Maje.footer.toggleAccordion =  function($targetContainer) {
	$(document).on('click', '.toggleMe', function(event) {
		event.preventDefault();
		event.stopPropagation();
		var orderShipping = $('.order-shipping').find('.shipping-method-desc');
		var parentClass = $(this).data('parentclass'),
			clickedItem = $('.'+parentClass).find('.open'),
			multitoggle = $(this).attr('data-multitoggle');
		if (multitoggle !== undefined) {
			$(this).toggleClass('open');
		} else {
			if ($(this)[0] === (clickedItem)[0]){
				$(this).toggleClass('open');
			}else{
				clickedItem.removeClass('open');
				$(this).toggleClass('open');
			}
		}

		if (orderShipping.length > 0) {
			if ($('.order-shipping .toggleMe').hasClass('open')) {
				orderShipping.css('display', 'block');
			} else {
				orderShipping.css('display', 'none');
			}
		}
	});

	$(document).on('click', function(event){
		event.stopPropagation();
		if ($('.click-out.open').length) {
			$('.click-out.open').removeClass('open');
		}
		if ($('.click-out.showMe').length) {
			$('.click-out.showMe').removeClass('showMe');
		}
	});
}

Maje.footer.bindEvents = function(){
	Maje.footer.slideAssurance();
	Maje.footer.menuHover();

	Maje.footer.salutationRadio();
	Maje.footer.shippingMethodRadio();
	Maje.footer.creditCardRadio();
	Maje.footer.inputFilesContactus();
	Maje.cart.couponCode();
	Maje.cart.taxesCalculation();

	$(window).resize(function(){
		Maje.footer.slideAssurance();
		Maje.product.moveElementPopinProduct();
		$('.overlayMob').css('height', $(document).height());
	});

	//Maje.footer.loginSuccess();
	Maje.footer.initNewsletterPopin();
	Maje.footer.tagErrorMessages();
	Maje.footer.removeErrorMessage();
	Maje.footer.menumobile();
	Maje.footer.showmobile();
	Maje.footer.toggleAccordion();

	Maje.footer.showSizegrid();
	Maje.checkout.disableAutofill();
}

Maje.checkout.disableAutofill = function(){
	if (app.constants.CAPADRESSE_ENABLED) {
		$('form').attr('autocomplete','nope');
		$("form :input").each(function(){
			$(this).attr('autocomplete','nope');
		});
	}
}

Maje.cart.bindEvents = function() {
	Maje.cart.couponCode();
	Maje.cart.taxesCalculation();
	Maje.cart.impulseSell();
	Maje.cart.initSwitchSize();
	Maje.cart.cartButtonsInit();
	Maje.cart.resetButtonsInit();
	Maje.cart.createAccountButtonsInit();
    Maje.cart.services();
}

Maje.cart.createAccountButtonsInit = function() {
	$(document).off('click', '#easy-registration-create-new-accout');
	$(document).on('click', '#easy-registration-create-new-accout', function(e){
		e.preventDefault();

		var submitButton = $(e.target);
		var urlData = {
				source:"cart",
				format:"ajax"
				};
		urlData[submitButton.attr("name")] = "confirm";

		var $form = $('#EasyRegister');
		var url = app.util.appendParamsToUrl($form.attr('action'),urlData);

		if($form.validate().form()) {
			$.ajax({
				url: url,
				type: $form.attr('method'),
				data: $form.serialize(),
				success: function(data) {

					var isErrorMessage = $(data).find("#create-new-accout-error").val() === "true";

					if(isErrorMessage){
						$('#login_popin').html(data);
						// Reactivate the validator for each reload of the popin
						// for successive failures
						app.validator.init();
					} else {
						location.assign(submitButton.data('redirect'));
					}
				}
			});
		}
	});
}

Maje.cart.resetButtonsInit = function() {
	$(document).on('click', '#password-reset a', function(e){
		e.preventDefault();

		var urlPassword = $(this).attr('href');

		app.ajax.load({
			url:urlPassword,
			callback: function (data) {
				$('#resetContainer').html(data);
				$('#PasswordResetForm').attr('action',app.urls.passwordResetDialogAjax);
				app.validator.init();
			}
		});
	});

	$(document).on('click', '#submitResetPassword', function(e){
		e.preventDefault();

		var $form = $('#PasswordResetForm');
		var url = app.util.appendParamsToUrl($form.attr('action'),{source:"cart"});

		if($('.form-group-reset-password div.form-row input.email').hasClass('valid')) {
			$.ajax({
				url: url,
				type: $form.attr('method'),
				data: $form.serialize(),
				success: function(data) {
					var isErrorMessage = !!$(data).find(".error-message").length;

					if(isErrorMessage){
						var fromy = $(data).filter('div#wrapper').find('.resetPassword');
						$('#resetContainer').html(fromy);
					} else {
						$('#resetContainer').html('');
					}
				}
			});
		}
	});
}

Maje.cart.cartButtonsInit = function() {
	var isCurrentCustomerAuthenticated = app.clientcache.IS_AUTHENTICATED;

	$('.continueShopping').on('click', function(e) {
		e.preventDefault();
		app.page.redirect(app.urls.continueShopping);
	});

	$('.checkoutCart').on('click', function(e) {


		if (!isCurrentCustomerAuthenticated) {
			e.preventDefault();
			if($(window).width() >= 1024){

				var dlgOptions = $.extend({}, app.dialog.settings, {
					dialogClass: "login-checkout primary-content login block-shadow"
				});
				var dlg = app.dialog.create({target:$('#popup-login'), options : dlgOptions});
				app.ajax.load({
					url:app.urls.loginDialogAjax,
					target:dlg,
					callback: function () {
						dlg.dialog("open");	// open after load to ensure dialog is centered
						$('.ui-widget-overlay').on('click', function(){ $(".ui-dialog-content").dialog('close'); });
						app.validator.init(); // re-init validator
					},
					data : null
				});
			} else {
				window.location.href = app.urls.loginDialogAjax;
			}
		}

	});

	$(document).on('click touchstart','.order-shipping-methods li a',function(e){
		e.preventDefault();
		var $target = $('.ordertotals');
		var selectedShippingMethodId = $(this).attr('id');
		app.ajax.load({
			url:app.urls.setShippingMethodFromCart,
			callback: function (data) {
				$target.html(data);
				var zipCodeValue = $('.estimation-tax-zipcode').val();
				Maje.cart.refreshCartAmounts(zipCodeValue);
			},
			data : {
				shippingMethodID : selectedShippingMethodId,
				reloadContent : true,
				p_ft : false,
				p_tce : true
			}
		});
	});
}

Maje.cart.couponCode = function() {
	var couponLabel = $(".cart-coupon-code label"),
		promoInput = $(".js-add-coupon "),
		crmCouponSelectBox = $(".left-cart-col .crm_coupon"),
		crmCouponValue = $(".left-cart-col .hidden-crm-coupon"),
		crmCouponSubmit = $(".left-cart-col #button-apply-crm-coupon");

	couponLabel.on('click', function () {
		if (!$(this).hasClass('open')) {
			Maje.header.showElementInViewport(promoInput);
		}
	});

    if(promoInput.is(":focus")){
        if (window.event.type == "keypress" && window.event.keyCode == 13){
            $(".cart-coupon-code button").trigger("click");
            return false;
        }
    }

    crmCouponSelectBox.on('change', function() {
    	var couponCode = $(this).val();
    	crmCouponValue.val(couponCode);
    });
    crmCouponSubmit.off('.crmcoupon').on('click.crmcoupon', function() {
    	var couponVal = crmCouponValue.val();
    	$(this).prop('disabled', true);
		if (couponVal != '') {
			$.ajax({
				url: app.urls.addCRMCoupon,
				cache:false,
				data: { couponCode: couponVal },
				type : "POST",
				dataType : "json",
				success: function(response) {
					if (response.response_code === 'OK') {
						location.reload();
					} else {
						console.log(app.resources[response.response_message]);
					}
				}
			});
		}
    });

}

/*** SHOW CLICKED SERVICE TEXT ON THE VIEW PORT ***/
Maje.cart.services = function(){

    var serviceTitle = $(".services li span"),
        serviceText;
        serviceTitle.on('click', function(event){
            serviceText = $(this).next('p');
            Maje.header.showElementInViewport(serviceText);
        });
}

/** GLOBAL FUNCTION TO SHOW ELEMNT IN VIEWPORT **/
Maje.header.showElementInViewport = function(elementToShow) {

    var windowVar = $(window);

    var heightToSet = windowVar.innerHeight() - 60,
        elementPosition = elementToShow.offset().top + 30,
        viewportDim = windowVar.scrollTop() + windowVar.innerHeight();
    if (viewportDim < elementPosition) {
        $('html, body').animate({
            scrollTop: elementToShow.offset().top - heightToSet
        }, 200);
    }
}

Maje.cart.taxesCalculation = function(){
	var input = '.estimation-tax-zipcode';

	if($(".shipping-form").length > 0){
		if($("#zipCodeEstimated").length > 0 && $.isNumeric($("#zipCodeEstimated").val())){
			$("input.zip").val($("#zipCodeEstimated").val());
		}
	}

	$(document).on('click', input, function(){
		if($(input).attr("placeholder") == $(input).attr("value")){
			$(input).val("");
		}
	});

	$(document).on('focusout', input, function(){
		if($(input).attr("value") == ""){
			$(input).val($(input).attr("placeholder"));
		}
	});

	document.onkeypress = function(){
		if($(".estimation-tax-zipcode").is(":focus")){
			if (window.event.type == "keypress" && window.event.keyCode == 13){
				$(".estimation-tax-submit").trigger("click");
				return false;
			}
		}
	};

	$(".estimation-tax-submit").unbind().on('click', function(e){
		e.preventDefault();
		var zipCodeValue = $(".estimation-tax-zipcode").val();
		Maje.cart.refreshCartAmounts(zipCodeValue);
	});

	$("input[name='addressbook']").unbind().change(function(e){
		e.preventDefault();
		var zipcode = $("input[name='addressbook']:checked").data("zipcode");
		Maje.cart.refreshCartAmounts(zipcode);
	});
}

Maje.cart.refreshCartAmountsData = function(zipCodeValue) {
	var estimationShippingCostValue = $("#estimation-shipping-cost").val();
	var estimationOrderSubTotalValue = $("#estimation-order-subtotal").val();
	var estimationShippingCost = isNaN(estimationShippingCostValue) ? 0 : estimationShippingCostValue;
	var estimationOrderSubTotal = isNaN(estimationOrderSubTotalValue) ? 0 : estimationOrderSubTotalValue;
	var checkoutPage = (typeof $("#checkoutpage").val() !== 'undefined') ? $("#checkoutpage").val() : '';
	return 'zipCode=' + zipCodeValue + '&estimationShippingCost=' + estimationShippingCost + '&estimationSubtotal=' + estimationOrderSubTotal + '&checkoutpage=' + checkoutPage + '&format=ajax';
}

Maje.cart.refreshCartAmounts = function(zipCodeValue) {

	if ($("#estimation-tax-value").length == 0) return false;

	var correctZipCode = zipCodeValue.toString().match(/^\d{5}([\-]?\d{4})?$/);
	var isCartShowStep = $(".estimation-tax-zipcode").length > 0;
	var data = Maje.cart.refreshCartAmountsData(zipCodeValue);

	$.ajax({
		url: 	app.urls.refreshBasketAmounts,
		data: 	data,
		dataType: 'json',
		success:
			function(responseData){
			if(responseData.error || !correctZipCode) {
				var errorReason = app.resources.CHECKOUT_ESTIMATEDTAX_NOTESTIMATED;
				$("#estimation-tax-value").removeClass("error");

				/* If we are on other step than the cart-show step, we display a detailed error message */
				if(!isCartShowStep && responseData.errorReason.length > 0) {
					errorReason = responseData.errorReason;
					$("#estimation-tax-value").addClass("error");
				}
				$("#estimation-tax-value").html(errorReason);
			} else {
				$("#estimation-tax-value").removeClass("error");
				$("#estimation-tax-value").html(responseData.taxes);
			}
		   $("#order-total-value").html(responseData.total);

		},
		error:
			function(responseData){
				$("#estimation-tax-value").html(app.resources.CHECKOUT_ESTIMATEDTAX_NOTESTIMATED);
			}
	});
	return false;
}

Maje.cart.impulseSell = function() {
	$('.impulse-push button').click(function() {
		var postdata = {
			cartAction : "add",
			pid : $(this).prev('.product-tile').data('itemid')
		}

		app.cart.update($.param(postdata), function(data) {
			location.reload();
		});
	});
	$("#cart-items-form").validate({
		errorPlacement: function(error, element) {
			error.appendTo('#errorMsg');
		}
	});
}

Maje.cart.initSwitchSize = function(){
	$("body").off("click", ".switchSize");
	$("body").on("click", ".switchSize", function(){
		Maje.product.switchSize();
	});
}

Maje.footer.salutationRadio = function(){
	var btn = '.radio-salutation';
	var input = 'div.salutation .input-radio';

	$(document).on('click',input, function(){
		fillHiddenSalutationInput(this);
	});

	function fillHiddenSalutationInput(that){
		$(that).closest('div.salutation').find('input.radiohidden').attr('value', $(that).val());
		$(that).closest('div.salutation').find('input.radiohidden').trigger('click');
	}

	// If an input was checked before the handlers were bound, make sure the input is filled in
	$(input + ':checked').each(function() {
		fillHiddenSalutationInput(this);
	});
}
Maje.footer.shippingMethodRadio = function(){
	var btn = '.radio-shippingmethod';
	$(document).on('click', btn, function(){
		// we substring(10) the id who begin with container- in way to abtain if of childrens
		var currentid = $(this).attr('id');
		var ischeck = $("#"+currentid.substring(10)).prop("checked");
		$(this).parent().children().removeClass("selected");
		if(ischeck){
			$(this).addClass("selected");
		}else{
			$(this).removeClass("selected");
		}
	});
}
Maje.footer.creditCardRadio = function(){
	var btn = '.radio-creditcard';
	// Maje uses a different way of handling the required marker so it's done a bit differently here too
	var cvnLabel = $("div.form-row.cvn label span");

	function selectCCard(event) {
		// Be careful with the event object, as it is a plain object at init instead of an Event object.
		// See call a few lines below
		if(event.target) {
			$('#ccard-type-container').children().addClass("inactive");
			var currentid=$(event.target).attr('id');
			$('#container-'+currentid).removeClass("inactive");
			//$("#dwfrm_billing_paymentMethods_creditCard_type").attr('value',$("#"+currentid).attr('value'));
			$("input[name='dwfrm_billing_paymentMethods_selectedPaymentMethodID']").attr('value',$("#"+currentid).attr('value'));
		}
	}

	$(document).on('click', btn, selectCCard);
	// Init in case we have a Maestro card selected at loading
	selectCCard({"target" : $("input.radio-creditcard:checked")[0] });

	var isHideAlipay = $('#js-isHideAlipay');
	var isHideWechat = $('#js-isHideWechat');
	var isHideOnWechatBrowser = $('#js-isHideOnWechatBrowser');

	var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	var isWeChat = /micromessenger/i.test(navigator.userAgent);
	if (isMobile && isHideWechat && isHideWechat.length !== 0) {
		$('#container-ccard-adyen_wechatpay').addClass('hidden');
	}
	if ((isMobile && isHideAlipay && isHideAlipay.length !== 0) 
		|| (isWeChat && isHideOnWechatBrowser && isHideOnWechatBrowser.length !== 0)) {
		$('#container-ccard-adyen_alipay').addClass('hidden');
	}
}
Maje.footer.inputFilesContactus = function() {
	var names = [];
	var filesMax = 4;

	//Les types de fichier autorisé dans l'input file
	var allowedFiles = ["pdf", "jpg", "jpeg", "gif", "png", "txt", "doc", "odt", "docx", "ppt", "pptx", "xls", "xlsx"];

	$(".input-file").on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
		e.preventDefault();
		e.stopPropagation();
	}).on('drop change', function(e) {

		if (e.type == "drop"){
			droppedFiles = e.originalEvent.dataTransfer.files;
			//Check la compatibilité du drag and drop et dataTransfer
			if (droppedFiles === undefined){
				return;
			}
		} else if (e.type == "change") {
			droppedFiles = $(this).get(0).files;
		}

		//Comportement OLD IE (8/9)
		if (droppedFiles === undefined && $(this).val() != 0){
			droppedFiles = $(this).val();
			droppedFiles = droppedFiles.split("\\");

			$(".list-input-file").empty();
			$(".list-input-file").append("<li class='center'><span class='name-file'>" + droppedFiles[2] + "</span></li>");

			names.push(droppedFiles[2]);
			return;
		}
		//On arrete la fonction si c'est un OLD IE (pas d'upload ajax);

		var limit = names.length + droppedFiles.length;

		//On limite à 4 fichiers max
		//Si il y en a plus on affiche l'erreur et arrete la fonction
		if (limit > filesMax){
			$(".error-files").show();
			return;
		} else {
			$(".error-files").hide();
			$(".error-type-files").hide();
		}

		//On passe sur tout les fichiers à upload
		$.each( droppedFiles, function(i, file) {

			//Verification du type de fichier ref var allowedFiles
			  var File_Ext = file.name.split('.');
				  File_Ext = File_Ext[File_Ext.length - 1].toLowerCase();

				  if (allowedFiles.indexOf(File_Ext) != -1) {
					  //Push les noms des files dans un tableau var names
					names.push(file.name);
				  }
				  //On arrete la fonction si ce n'est pas un type de fichier accepté
				  else {
					  $(".error-type-files").show();
					  return;
				  }

				  var form = $("#RegistrationForm");
				var input = form.find('input[type="file"]');
				var ajaxData = new FormData();
				ajaxData.append( input.attr('name'), file );

				// Ajax Upload file
				//**https://css-tricks.com/drag-and-drop-file-uploading/**

				$.ajax({
					url: form.data('uploadfile'),
					type: form.attr('method'),
					data: ajaxData,
					dataType: 'json',
					cache: false,
					contentType: false,
					processData: false,
					success: function(data) {

					}
				});

			//On affiche les fichiers dans la zone de drag un drop
			var nbFiles = names.length;
			$(".list-input-file").empty();
			for (var j = 0; j < nbFiles; j++) {
				var fileNameTotal = names[j];
				var fileName = fileNameTotal;
				var arrayName = names[j].split(".");
				if(arrayName[0].length > 28){
					var this_name = arrayName[0].substring(0, 28);
					var this_ext = arrayName[1];
					fileName = this_name + "..." + this_ext;
				}

				$(".list-input-file").append("<li><span class='name-file' data-filename='" + fileNameTotal + "'>" + fileName + "</span><span class='delete-file'></span></li>");
			}

			//Supression d'un fichier, on le retire de l'array names et supprime son affichage
			//Le fichier est déjà upload
			$(".delete-file").on("click", function() {
				var indexFiles = $(this).parent("li").index();

				delete names[indexFiles];

				// Delete file upload
				$.ajax({
					url: form.data('delfile'),
					data: 'FileName=' + $(this).parent("li").find(".name-file").data("filename"),
					success: function(data) {

					}
				});

				names = jQuery.grep(names, function(val){
					if( val == '' || val == NaN || val == undefined || val == null ){
						return false;
					}
					return true;
				});
				$(this).parent("li").remove();
				$(".error-files").hide();
				$(".error-type-files").hide();
				//var names renvois les noms des images liées au formulaire
			});
		});

		// To send form. If enctype value is "multipart/form-data", the form is no send
		$("#RegistrationForm").on("submit", function() {
			var form = $(this);

			if (form.valid()) {
				var fileList = form.find("ul.list-input-file li");
				var action = form.attr("action");

				fileList.each (function() {
					var fileName = $(this).find(".name-file").data("filename");

					if (action.indexOf(fileName) === -1) {
						if (action.indexOf("?") >= 0) {
							action += "&files=" + fileName;
						}
						else {
							action += "?files=" + fileName;
						}
					}
				});

				form.attr("action", action);
			}

			form.attr("enctype", "application/x-www-form-urlencoded");
		});

	});
}

Maje.footer.callAjaxNewsletter = function(targetUrl, email, country, currElt) {

	// currElt is the current DOM form element triggering the Ajax call (for NL subscription)

	targetUrl += "?email=" + email;

	if (country && country !== "undefined") {
		targetUrl += "&country=" + country;
	}

	$.ajax({
		url: targetUrl
	}).done(function(data) {
		var res = data.Result;
		var customerAlreadyMember = data.CustomerAlreadyMember;

		if(res != null && res != 'none' && res != '')
		{
			var msgDone = (customerAlreadyMember === null || customerAlreadyMember === false) ? "msgdone" : "msgalreadydone";
			var className = (customerAlreadyMember === null || customerAlreadyMember === false) ? "validNews" : "validAlreadyRegister";

			// test made to distinguish whether the submission of the NL form comes from central part form or footer one
			if(currElt.attr('id') == 'newsletterFormGift'){

				$("div#formNewsletterGift div.news-letter > p").empty();
				$("div#formNewsletterGift div.news-letter > p[data-attribute = exclusive]").css('display', 'block').append($("#submitnewsletterGft").data(msgDone));
				$("div#formNewsletterGift form#newsletterFormGift").hide();
				}
			else if(currElt.attr('id') == 'popupNewsletterForm'){
				$(".popup-newsletter-top, .popup-newsletter-bottom").hide();
				$("div.home-popup-newsletter").addClass(className);
				$(".popupNewsletterConfirm").show();

				/* setTimeout(function(){
					$('.home-popup').stop().fadeOut();
				}, delayBeforeDisplayPopup); */
			} else {

				$(".news-letter p.msg").empty();
				$("div.news-letter p.msg").append($("#submitnewsletter").data(msgDone));
 				$("div.news-letter p.msg").show();
				$("div.footerNewsletter").addClass(className);
				$("div.footerNewsletter").show();
				$("div.footerNewsletter").css( "display", "table");
				$("form#newsletterForm").hide();


				$(".newsletterErrorMsg").empty();
				$("div.newsletterEndMsg > span").empty();
				$("div.newsletterEndMsg > span").append($("#submitnewsletter").data(msgDone));
				$("div.newsletterEndMsg").show();
				$("div.newsletterEndMsg").css( "display", "table");
				$("form.newsletterEndForm").hide();

				}

				$("p.mandatory").hide();

				$(document).trigger("newsletter.subscription");
			}
			else // WTF ????? else else
			{
				$(".news-letter p.msg").empty();
				$(".news-letter p.msg").append($("#submitnewsletter").data("msgerror"));
				$("div.news-letter p.msg").show();
				$(".newsletterErrorMsg").empty();
				$(".newsletterErrorMsg").append($("#submitnewsletter").data("msgerror"));

				if(currElt.attr('id') == 'popupNewsletterForm'){
					$("div.home-popup-newsletter p").empty().append($("#submitnewsletter").data("msgerror"));
				}
			}
		}).fail(function() {
			$(".newsletterErrorMsg").empty();
			$(".newsletterErrorMsg").append($("#submitnewsletter").data("msgcomerror"));

			if(currElt.attr('id') == 'popupNewsletterForm'){
				$(".popup-newsletter-top, .popup-newsletter-bottom").hide();
				$("div.popupNewsletterConfirm p").empty().append($("#submitnewsletter").data("msgcomerror"));
				$(".popupNewsletterConfirm").show();
			}
		});
	}

Maje.footer.initNewsletterPopin = function(){
	var delayBeforeDisplayPopup =  $('#delayAfterResponsePopupNewsletter').val() * 1000;

	var showPopin = function() {


		$(document).trigger("newsletter.subscription");

		/* Need to extend settings here as app.validator.init() sets up every form with the same settings
		 * and the validate() function doesn't create a new validator object if one already exists.
		 */
		var newsLetterEndForm = $("#newsletterEndForm");
		if (newsLetterEndForm.length) {
			$.removeData(newsLetterEndForm[0], 'validator');

			var newsletterFormSettings2 = $.extend(true,
				{
					debug : true,
					errorPlacement : function(error, element) {
						error.insertAfter(element).css('display', 'block');
					},
					submitHandler : function(form) {
						var email = encodeURIComponent($(form).find('input.nlemail').val());
						var country = encodeURIComponent($(form).find('select.nlcountry').val());
						var targetUrl = $(form).attr("action");
						Maje.footer.callAjaxNewsletter(targetUrl,email,country,$(form));
					}
				}, app.validator.settings);

			// Unbind these so that jQuery validate can rebind them cleanly
			newsLetterEndForm.unbind('submit').unbind("invalid-form.validate").validate(newsletterFormSettings2);
		}
	}

	$(document).on('click', '.showNewsletterSubscription', function(){
		showPopin();
	});

	$('.newsletterForm').off('submit').on('submit', function(e) {
		e.preventDefault();
		if ($(this).valid()) {
			var email = encodeURIComponent($(this).find('input.nlemail').val());
			var country = encodeURIComponent($(this).find('select.nlcountry').val());
			var targetUrl = $(this).attr("action");
			Maje.footer.callAjaxNewsletter(targetUrl,email,country,$(this));
		}
	});

	$('.popupNewsletterForm').off('submit').on('submit', function(e) {
		e.preventDefault();
		if ($(this).valid()) {
			var email = encodeURIComponent($(this).find('input#nlemail').val());
			var country = '';//encodeURIComponent($(this).find('select.nlcountry').val());
			var targetUrl = $(this).attr("action");
			Maje.footer.callAjaxNewsletter(targetUrl,email,country,$(this));
		}
	});

	function getQueryVariable(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return null;
	}
}
Maje.footer.tagErrorMessages = function() {

}
Maje.footer.removeErrorMessage = function(){
	$(document).on('click', '.input-text', function(){
		$(this).parent().find('.error-mesage').remove();
	});
}
Maje.footer.menumobile = function() {
	var collapsBtn = $('.collapsIt'),
		clickedElmt;
	collapsBtn.on('click', function(e){
		if (this !== clickedElmt ) {
			$(this).closest('.footerContent').find('.active').removeClass('active');
		}
		$(this).parent().toggleClass('active');
		clickedElmt = this;
	});
}
Maje.footer.showmobile = function() {
	var collapsBtn = $('.menuFooter > ul > li.list .item-toggle'),
		clickedElmt;
	collapsBtn.on('click', function(e){
		if (this !== clickedElmt ) {
			$(this).closest('.menuFooter').find('.list.active').removeClass('active');
		}
		$(this).parents('.list').toggleClass('active');
		clickedElmt = this;
	});
}
Maje.minicart.initRemoveButtons = function(){
	$(".minicart-remove-button").click(function(e){
		e.preventDefault();

		$(document).trigger("cart.removefromcart", [$(this).data()]);


		var url = $(this).attr("href");
		jQuery.ajax({
			url:url,
			cache:false
		}).done(function(data) {
			var res = data.split("§§§");
			jQuery('#mini-cart').html(res[0]);
			if(jQuery('#cartNumberItems') && jQuery('#cartNumberItems').html() != ''){
				jQuery('#cartNumberItems').html(res[1]);
			}
			if(jQuery('#cart-items-form') && jQuery('#cart-items-form').html() != ''){
				jQuery('#cart-items-form').html(res[2]);
			}
			Maje.minicart.initRemoveButtons();
			if ($('.mini-cart-products .mini-cart-product').length == 0) {
				if ($('#maskLayer').length){
					$('#maskLayer').remove();
				}
			}
		});
	});
}

// Maje.header.heightMiniCart = function(){
// 	var heightWindow = window.innerHeight;
// 	var MiniCart = $(".mini-cart-content");
// 	var smallCartClass = "small-cart";

// 	if(heightWindow < 750){
// 		MiniCart.addClass(smallCartClass);
// 	}
// };

var listenerAdded = false;

Maje.header.moveToFixe = function(){
	var classHeader = '#header';
	var classHeaderHeight = $(classHeader).outerHeight(true);
	var menuLi = '.menuMainMaje .listMenu li';
	var isMenuOpen;
	var isTab;
	var sticky = false;
	var filterListProduct = '.filterListProduct';
	var scrollBackNormal=classHeaderHeight;

	function removeMaskLayer() {
		if ($('#maskLayer').length != 0) {
			$('#maskLayer').remove();
		}
	}

	function scrollControl() {
		var position = scrollBackNormal;
		if ($(window).width() > 1023 && !$('.specialEvent').length) {
			$(window).scroll(function(){
				isMenuOpen = $('.slideMenu').css('left') == '0px';
				if ($(window).scrollTop() < 156 && sticky === false) {
					$(classHeader).removeClass('sticky');
					$(filterListProduct).removeClass('sticky').removeAttr('style')
						.siblings('.breadcrumb').removeAttr('style');
					$('body').removeClass('sticky-active');
					sticky = true;
				} else if ($(window).scrollTop() > 180 && $(window).width() > 1023 && sticky === true) {
					sticky = false;
					$(menuLi).each(function(){
						if ($(menuLi).hasClass('hideSticky')) {
							$(classHeader).removeClass('sticky');
							$(filterListProduct).removeClass('sticky').removeAttr('style')
								.siblings('.breadcrumb').removeAttr('style');
							$('body').removeClass('sticky-active');
						} else {
							$(classHeader).addClass('sticky');
							// adjust height sticky refinement bar
							var headerHeight = $('#header.sticky').height();
							$(filterListProduct).addClass('sticky').css({
								top: headerHeight
							}).siblings('.breadcrumb').css({
								top: headerHeight
							});
							$('body').addClass('sticky-active');
						}
					});
				}
			});
		} else {
			$(classHeader).removeClass('sticky');
			$(filterListProduct).removeClass('sticky').removeAttr('style')
				.siblings('.breadcrumb').removeAttr('style');
			$('body').removeClass('sticky-active');
			if (scroll > position && $(classHeader).hasClass("fixed")) {
				$(filterListProduct).addClass("hideFly");
				$(filterListProduct).removeClass("fixed showFly");
			} else {
				if (scroll <= scrollBackNormal && !($(classHeader).hasClass("fixed"))) {
					$(filterListProduct).removeClass("fixed hideFly showFly");
				} else if (scroll > scrollBackNormal && $(classHeader).hasClass("fixed")) {
					$(filterListProduct).addClass("fixed showFly");
					$(filterListProduct).removeClass("hideFly");
				}
			}
			position = scroll;
		}
	}

	if (!$('.pt_cart, .pt_checkout, .pt_account_checkout').length) {
		scrollControl ();
	}

    var $slideMenu = $('.slideMenu');
    if(window.outerWidth<1025)
	{
		$slideMenu.find('.listItem > a').on('click', function() {
			var $this = $(this).closest('.listItem');
			if ($this.find('.subMain').length) {
				if (!$slideMenu.hasClass('activeSub')) {
					$slideMenu.addClass('activeSub');
					$this.addClass('active').siblings('.listItem').removeClass('active');
				} else {
					if ($this.hasClass('active')) {
						$this.removeClass('active');
						$slideMenu.removeClass('activeSub');
					}
				}
				return false;
			}
		});
	}

	$slideMenu.find('.close-sub-menu').on('click', function() {
		$(this).closest('.listItem').removeClass('active');
		$slideMenu.removeClass('activeSub');
	});


	$('.mini-wishlist-link').on('click', function(e) {
		if ($(window).width() < 1024) {
			e.preventDefault();
			app.minicart.close();
			if ($('.mini-wishlist-content').css('display') != 'block') {
				app.wishlist.slide();
				$('#maskLayer').one('click', function() {
					app.wishlist.close()
				})
			} else {
				app.wishlist.close();
			}
		}
	});

	$(document).on('click.mimiwishlist', '#mini-wishlist .add-to-cart-button' , function(e){
		e.preventDefault();
		var $form = $(this).next('form'),
			data = $form.serialize();
		var productData = $(this).data("product");
		app.cart.update(data, function (response) {
			app.minicart.show(response);
			Maje.minicart.bindEvents();
			if (productData) {
				$(document).trigger("pdp.addtocart", [productData]);
			}
		});


		return false;
	});

	$('.basketItem').on('click', function(e) {
		isTab = $(window).width() > 767 && $(window).width() < 1024;

		if (isTab) {
			e.preventDefault();
			if ($('.mini-cart-content').css('display') != 'block') {
				app.minicart.slide(false);

				$('#maskLayer').one('click', function(e) {
					app.minicart.close();
				});
			} else {
				app.minicart.close();
			}
		}
	});
};

Maje.header.loginAndRedirect = function() {
	var $loginLink = $('#header .userInfoHeader a.user-login');
	var connectUrl = $loginLink.attr('href');
	if (connectUrl) {
		var redirectUrl = window.location.href;

		// Hack : redirect to the full /homepage url if we're on the home, so that additional login params can be added.
		if (window.location.pathname == '/') {
			redirectUrl = app.urls.abshomepage;
		}

		redirectUrl = redirectUrl.indexOf('http') < 0 ? 'http://' + redirectUrl : redirectUrl;
		connectUrl = connectUrl.indexOf('?') > 0 ? connectUrl + '&' : connectUrl + '?';
		connectUrl = connectUrl + 'redirectto=' + redirectUrl;
		$('#header .userInfoHeader a.user-login').attr('href', connectUrl);
	}

	$loginLink.closest('li').on('mouseenter.loginpopup', function(){
		$(this).addClass('hover');
	}).on('mouseleave.loginpopup', function(){
		if ( !$(this).find('input:focus').length ) {
			$(this).removeClass('hover');
		}
	})
};

// gestion du menu en mode mobile
Maje.header.menu = function(){
	var header = $('#header');
	$('.btnMenuSlide').attr("data-menu-slide","");
	// au click on affiche ou non le menu
	$(document).on('click','.btnMenuSlide', function(e){
		e.preventDefault();
		$('body').toggleClass('open-sub');
	});
	Maje.header.icon();
	Maje.header.cloneLogo();
	Maje.header.numbItemBasket();
	$(window).resize(function(){
		if ($(window).width() > 1024 && !$('.specialEvent').length){
			$('#header header').css({'position':'static'});
			$('#wrapper, .mainSubList').attr('style', '');
		}
		// function qui permet de deplacer certains element du menu et du search au resize
		Maje.header.cloneLogo();
		Maje.header.numbItemBasket();
	});
};

Maje.header.expandMenu = function(element, trigger, contentSlide, typeBlock, mode){

		var blockTab = $(element);
		// si l'element existe
		var widthWindow = 767;


		$(document).on(mode, trigger, function(e){
				e.preventDefault();
				if($(window).width() < 767){
					$(this).toggleClass('active');
					$(this).siblings(typeBlock).removeClass('active');
					$(this).next(contentSlide).slideToggle();
					$(this).siblings(typeBlock).next(contentSlide).slideUp();
				}else{
					return '';
				}
			});
};
Maje.header.cloneLogo = function(){
	var existLogo = $('.mobileLogo').length;
	if (Modernizr.mq('(max-width: 767px)') && !$('#header').hasClass('light')){

		if(existLogo === 0){
			var cloneLogo = $('.maje:hidden');
			cloneLogo.clone().addClass('mobileLogo').insertAfter('.headLogo');

			var logoMobile = $('.mobileLogo');
		}
	} else {
		if(existLogo > 0){
			$('.mobileLogo').remove();
		}
	}
};
Maje.header.icon = function(){
	var menuHeaderMob = $('.userInfoHeader > li');
	var firstMenu = $('.headLogo');

	firstMenu.each(function(){
		var icon = $(document.createElement('span')).addClass('iconArrowTop');
		var classIcon = $('.iconArrowTop');
		var existIcon = classIcon.length;
		if($(window).width() < 960){
			if(existIcon === 0){
				$(this).append(icon);
			}
		}
	});

	menuHeaderMob.each(function(){
		var icon = $('<span class="iconArrowTop"></span>');
		var classIcon = $('.iconArrowTop');
		var existIcon = classIcon.length;
		if($(window).width() < 960){
			if(existIcon === 0 || existIcon <= 4){
				$(this).append(icon);
			}
		}
	});
};

Maje.header.numbItemBasket = function(){
	var nb_basket = $('#mini-cart .numberItemBasket');
	var nb_basket2 = $('.basketItem .numberItemBasket');
	var nb_basket_length = $('.numberItemBasket').length;
	var cloneNb = nb_basket.clone();
	var nb_basketText = nb_basket.text();
	//alert(nb_basketText);

	if (nb_basket_length != 2){
		cloneNb.appendTo('.basketItem');
	}

	var dataNb = nb_basket.text();
	var dataNb2 = nb_basket2.text();

	var updateBasket = dataNb;

	if(dataNb != dataNb2){
		updateBasket = dataNb;
	}else{
		updateBasket = dataNb2;
	}
nb_basket2.text(function(){
		return updateBasket;
	});

};

Maje.header.emptyBasket = function(){
	var classBasket = '.mini-cart-total';

	$(document).on('hover', classBasket, function(e){
		e.preventDefault();
	});
};

Maje.product.bindEvents = function(){
	//gestion de l'affichage des filtres
	Maje.product.scrollFilter();
	// Maje.product.toggleFilter('.blckFilter','.btnTri','.subFilterTri','click');

	// Maje.product.closeToggleFilter();

	// gestion de la classe active sur les listes de filtres
	Maje.product.activeFilterClass ('.contentFilter','li', '.linkFilter', 'click');

	//gestion de la modification de l'image produit sur le listing produit au passage de la souris
	Maje.product.toggleImage();

	//afficher les tooltips lorsque les produits sont indisponibles
	Maje.product.tooltip();

	//gestion de l'affichage de la popin d'envoi de mail a un ami
	Maje.product.sendtofriendpopin();

	// remove element
	Maje.product.disable();

	// Affichage de la video
	Maje.product.displayVideo();

	// Chargement ajax des listes produits
	Maje.product.filters();
	Maje.product.pricePromoAlign();

	Maje.product.descriptToggle();
	Maje.product.scrollTop();
	Maje.product.addSelectedClass();

	// Slider page product
	Maje.product.slider(".product-image-container .main-product-slider");

	Maje.product.crossSellToggle();

	Maje.product.productNameBold();
	Maje.product.eventLookComplet();

	//Handle Maje Exclusive page SMCP-2572
	Maje.product.majeexclusive();
	Maje.product.majeexclusiveswatches();

	Maje.product.quickActions();
};

Maje.product.majeexclusive = function() {
	if ($('.majeexclusive').length) {
		app.product.init();
	}
}

Maje.product.majeexclusiveswatches = function() {
	if ($('.majeexclusive #product-content .swatches.Color li').length == 1 ) {
		$('.majeexclusive #product-content .attribute.color').addClass('hide');
	}
}

Maje.product.productNameBold = function(){
	var productName = $('.productSubname');

	if (productName.length && !productName.find(".first-word").length && !$('.quickview').length){
		productName.each(function() {
			var word = $(this).html();
			var index = word.indexOf(' ');
			if(index == -1) {
				index = word.length;
			}
			$(this).html('<span class="first-word">' + word.substring(0, index) + '</span>' + word.substring(index, word.length));
		});
	}
}

Maje.product.initSwitchSize = function(){
	$("body").off("click", ".switchSize");
	$("body").on("click", ".switchSize", function(){
		Maje.product.switchSize();
	});
}

Maje.product.scrollTop = function(){
	var windowScrollTop = Math.round($(window).scrollTop());
	var btnVisible = 0;

	function btnScrollUp() {
			var sTop = $(window).height();
			sTop = sTop*0.7;
			if ((windowScrollTop >= sTop) && (btnVisible == 0)){
				btnVisible = 1;
				$('.scroll-top').animate({
				'opacity' : '1'
				}, {duration:500});
			} else if ((windowScrollTop < sTop) && (btnVisible == 1)){
				btnVisible = 0;
				$('.scroll-top').animate({
					'opacity' : '0'
				});
			}
	}

		$(window).scroll(function() {
			windowScrollTop = Math.round($(window).scrollTop());
			btnScrollUp()
		});
}

Maje.product.switchSize = function(){
	$('.swatches.size, .switchSizeCont').toggleClass('frSizeShow');
}

Maje.product.descriptToggle = function(){
	$(".wrapper-tabs").each(function() {
		var wrap = $(this);

		wrap.find(".tabs-menu li a").on("click", function(e) {
			e.preventDefault();

			if(wrap.find(".tabs-content div.active").length > 0){
				wrap.find(".tabs-content div").removeClass("active");
				wrap.find(".tabs-menu li").removeClass("active");
			}

			var el = $(this).closest('li');
			var menuIndex = el.index();
			wrap.find(".tabs-content div").eq(menuIndex).addClass("active");
			el.addClass("active");
		});

		$(this).find(".tabs-menu li.ancre").on("click", function(e) {
			var desc = $(".block-description-product");
			e.preventDefault();

			if(desc.length){
				var windowHeight = window.innerHeight;
				var top = parseInt(desc.offset().top);
				$("html, body").animate({ scrollTop: top - windowHeight / 4}, 250);
			}
		});
	});
};

Maje.product.crossSellToggle = function(){

	var $wrap = $(".wrapper-crossSell"),
		$tabs = $wrap.find("li.tab"),
		$contents = $wrap.find(".lastProductsSeenPush");

	/**
	 * Displays the right tab and its corresponding data
	 * @param {DOMElement} $el optional.the target element
	 */
	function displayTargetContent($el){
	var $first = $el || $wrap.find("ul>li:visible").eq(0),
		targetClass = $first.data("target"),
		$target = $contents.filter("."+targetClass);

		// "activate" the target tab element
		$tabs.removeClass("active");
		$first.addClass("active");

		// display the corresponding content
		$contents.removeClass("active");
		$target.addClass("active");
	}

	//Enable the feature on Maje.product.crossSellToggle call
	displayTargetContent();

	// wrap this behavior in a event handler
	$wrap.on("click", "li.tab", function(e){
		displayTargetContent($(this));
	});

}

Maje.product.slider = function(ProductSlider){
	var mySwiper;
	mySwiper = new Swiper(ProductSlider,{
		mode:'horizontal',
		loop: false,
		freeMode: false,
		calculateHeight: true,
		pagination: '.swiper-pagination',
		paginationClickable: true,
		preloadImages: false,
		lazyLoading: true,
		noSwiping: false,
		onSlideChangeEnd: slideChangeEnd
	});

	function slideChangeEnd(){
		Maje.zoomProduct.bindEvents.showOrginalVideoImage();
	}

	$(".product-list-images img").on("click", function() {
		if(window.innerWidth <= 768){
			$(".zoomMain").trigger("click");
		}
	});

}

Maje.product.quickviewslider = function(){
	if($(".quickview-swiper").length){
		var mySwiper = new Swiper(".quickview-swiper", {
			mode:'horizontal',
			loop: false,
			calculateHeight: true,
			noSwiping: false,
			onSlideChangeEnd: function(swiper) {
				if (swiper.activeIndex === 0) {
					$('.quickview-swiper .arrowLeftMj').css({display: 'none'});
				} else {
					$('.quickview-swiper .arrowLeftMj').css({display: 'block'});
				}
				if (swiper.activeIndex === swiper.slides.length - 1) {
					$('.quickview-swiper .arrowRightMj').css({display: 'none'});
				} else {
					$('.quickview-swiper .arrowRightMj').css({display: 'block'});
				}
			},
			onInit: function(swiper) {
				$(".quickview-swiper").addClass("init");
			 }
		});

		$(".arrowLeftMj").on("click", function(e) {
			e.preventDefault();
			mySwiper.slidePrev();
		});
		$(".arrowRightMj").on("click", function(e) {
			e.preventDefault();
			mySwiper.slideNext();
		});
	} else if ( $(".product-image-container .main-product-slider").length ) {
		Maje.product.slider(".product-image-container .main-product-slider");
	}
}

Maje.product.zoom = function(){
	var el = {
		imageZoom : '.main-image',
		closeCurrent : '.closeCurrent',
		imgProduct : '.imgProduct',
		zoomPopin : '.zoomPopin',
		urlVideo : '.urlVideo a'
	};

	var createElementPopin = function(selectorBody){
		var parentDiv = $(document.createElement('div')).addClass('zoomPopin');
		var closeCurrent = $(document.createElement('div')).addClass('closeCurrent iconClose');


		// create element of Popin
		parentDiv
		.appendTo($('body'))
		.append(closeCurrent);

	}

	var closePopin = function(){
		$(el.closeCurrent).on('click tap touchstart', removeElementPopin);
	};

	var removeElementPopin = function(){
		$('#wrapper').show();
		$('body').removeClass('zoomPopin');
		$('.zoomPopin').remove();
		var metaViewport = $('html').find("meta[name='viewport']");
		var saveViewport = 'width=device-width, user-scalable=yes, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0';
		metaViewport.attr('content', saveViewport);
	};


	var getImageZoom = function(){

		$('.zoomPopin').on('click', function(e){
			e.preventDefault();
			var imgscr = $(this).attr('hires');
			// Active le zoom
			var imageZoom = $('.main-image');
			removeElementPopin();
			// create popin
			createElementPopin('body');
			// event close popin
			closePopin();

			var createImg = $(document.createElement('img')).attr('src',imgscr);
			createImg.appendTo('.zoomPopin');

			$('#wrapper').hide();
			$('body').addClass('zoomBody');
		});
	}

};

Maje.product.toggleImage = function(){
	var imgsrc;
	var backsrc;
	var frontsrc;
	$(window).load(function() {
		$(document).on('mouseenter', '.product-tile .product-image .thumb-link, .product-tile .quick-actions',  function () {
			if(!$(this).parents("li").hasClass("grid-tile-rupture")){
				imgsrc = $(this).find('img').attr('src');
				backsrc= $(this).find('img').data('backsrc');
				$(this).find('img').attr('src', backsrc);
			}
		});

		$(document).on('mouseover', '.quick-actions, .quick-actions a',  function () {
			if(!$(this).parents("li").hasClass("grid-tile-rupture")){
				backsrc= $(this).closest('.product-tile').find('.product-image').find('img').data('backsrc');
				$(this).closest('.product-tile').find('.product-image').find('img').attr('src', backsrc);
			}
		}).on('mouseleave','.quick-actions, .quick-actions a', function(){
			if(!$(this).parents("li").hasClass("grid-tile-rupture")){
				frontsrc= $(this).closest('.product-tile').find('.product-image').find('img').data('frontsrc');
				$(this).closest('.product-tile').find('.product-image').find('img').attr('src', frontsrc);
			}
		});

		$(document).on('mouseleave', '.product-tile .product-image .thumb-link, .product-tile .quick-actions',  function (e) {
			e.preventDefault();
			if(!$(this).parents("li").hasClass("grid-tile-rupture")){
				imgsrc = $(this).find('img').attr('src');
				frontsrc= $(this).find('img').data('frontsrc');
				$(this).find('img').attr('src', frontsrc);
			};
		});
	});

	$(document).on("mouseenter", ".product-swatches .swatch-list a.swatch", function (e) {
	//$('.product-swatches').on("mouseenter", ".swatch-list a.swatch", function (e) {
		e.preventDefault();
		var tile = $(this).closest(".grid-tile");
		var thumb = tile.find(".product-image a.thumb-link img").filter(":first");
		var swatchImg = $(this).children("img").filter(":first");
		var data = swatchImg.data("thumb");
		var current = thumb.data('current');
		var colorName = swatchImg.data('colorname');

		if(!current) {
			thumb.data('current',{src:thumb[0].src, alt:thumb[0].alt, title:thumb[0].title});
		}

		thumb.attr({
			src : data.src,
			alt : data.alt,
			title : data.title
		});



		if (colorName != null) {
			tile.find('.vignette').each(function() {
				$(this).removeClass('active');
			});
			var selectedVignette = tile.find('.vignette.'+colorName);
			selectedVignette.addClass('active');

		}
	});

	$(document).on("mouseleave", ".product-swatches .swatch-list a.swatch", function (e) {
		var tile = $(this).closest(".grid-tile");
		var swatchImg = $(this).children("img").filter(":first");
		var colorName = swatchImg.data('colorname');
		if (colorName != null) {
			tile.find('.vignette').each(function() {
				if ($(this).hasClass('default')) {
					$(this).addClass('active');
				} else {
					$(this).removeClass('active');
				}
			});
		}
	});

}

Maje.product.scrollFilter = function (){
	// $('.filterResult .contentFilter').jScrollPane({
	// 	autoReinitialise: true,
	// 	mouseWheelSpeed : 20
	// });
};

Maje.product.addSelectedClass = function (){
	$('.subFilterTri ul li').click(function(e){
		e.preventDefault();
		$(this).removeClass('selected');
		$(this).addClass('selected');
	});
};

Maje.product.activeFilterClass = function (content, contentLink, link, mode){
	$(document).on(mode, link, function(){
		$(this).toggleClass('active');
		$(this).closest(contentLink).siblings(contentLink).find(link).removeClass('active');
	});

	var bodyContainer = $('body'),
		windowContainer = $(window);

	if ( $(window).width() > 1024 ) {
		$(document).on('mouseenter','.filterListProduct' ,function(e){
			Maje.product.scrollFilter();
			if ($('body.sticky-active').length) {
				$('#header').addClass('sticky');
			} else {
				bodyContainer.addClass('fixed');
			}
			windowContainer.off('scroll.closefilter');
		}).on('mouseleave','.filterListProduct', function(e){
			var isLandingMaje = $('.html-slot-container #landing-maje').length > 0; // banner on PLP
			if (!isLandingMaje) {
				$('#main').removeAttr('style');
			}
			bodyContainer.removeClass('fixed');
			windowContainer.on('scroll.closefilter', function(e){
				$('.filterListProduct .btnTri').removeClass('open');
			});
		});
	} else {
		$(window).on("resize",function(){
			if($(".subFilterTri").length > 0){
				var filterHeight= $(".subFilterTri").height() - $(".subFilterTri").offset().top - $(".blockDeleteFilter").height();
				$(".contentFilter").css("height", filterHeight);
			}
		});

		$(document).on('click','.blckFilter .toggleMe',function(e){
			var filterHeight= $(".subFilterTri").height() - $(".subFilterTri").offset().top - $(".blockDeleteFilter").height();
			$(".contentFilter").css("height", filterHeight);
			if (!$(this).hasClass('open')) {
				bodyContainer.addClass('fixed');
				if($(this).closest('.filterResult')) {
					$('.filterListProduct').addClass('bg-show');
				}
			} else {
				bodyContainer.removeClass('fixed');
				$('.filterListProduct').removeClass('bg-show');
			}
		});
	}

	// close all filters on click out
	$(document).on('click.closefilter', function(e) {
		var filterParentContainer = $(e.target).parents('.filterListProduct').length;
		var menuParentContainer = $(e.target).parents('.menuMainMaje').length;
		var filterOpen = $('.filterListProduct .btnTri.open').length;
		if ((!filterParentContainer && filterOpen) && (!menuParentContainer && filterOpen)) {
			e.preventDefault();
			e.stopPropagation();
			$('.filterListProduct .btnTri').removeClass('open');
			bodyContainer.removeClass('fixed');
			$('.filterListProduct').removeClass('bg-show');
		}
		if($(window).width() < 1024) {
			bodyContainer.removeClass('fixed');
			if(!$('.filterListProduct .btnTri').hasClass('open')) {
				$('.filterListProduct').removeClass('bg-show');
			}
			$('html').removeClass('fixed');
		}
	});
	// close filter panel with cross
	$(document).on('click','.filter-close',function(){
		$(this).closest(".blckFilter").find(".toggleMe")
			.removeClass('open')
			.removeClass('keep-open');
	});
};

Maje.product.toggleTab = function(element, trigger, contentSlide, typeBlock, mode){
	var blockTab = $(element);
	if (blockTab.length > 0){

		//gestion du menu depliant dans la liste produit
		var catid = $('.currentCategory').html();
		var currentCategory;
		$('.tabCategory').each(function(){
			if($(this).attr('catid') == catid){
				$(this).find(trigger).next(contentSlide).show();
				$(this).find(trigger).addClass('active');

			}
		});

		blockTab.find(trigger).on(mode, function(e){
				e.preventDefault();
				$(this).toggleClass('active');
				$(this).closest(typeBlock).siblings(typeBlock).find(trigger).removeClass('active');
				$(this).next(contentSlide).slideToggle();
				$(this).closest(typeBlock).siblings(typeBlock).find(contentSlide).slideUp();
			});
	}
};
Maje.product.cloneElement = function(){
	var widthW = 767;
	// en dessous d'une taille de 767
	if($(window).width() < widthW){
		var productSubName = $('.productSubname');
		var soldout = $('.product-add-to-cart #soldout').clone();
		var comingsoon = $('.product-add-to-cart #comingsoon').clone();
		var btnalertshow = $('.product-add-to-cart .btnAlertShow').clone();
		var newDivProduct = $(document.createElement('div'));
		var clonePrice = $('.productPrices').clone();
		var itemBlock = $('.priceTitleProduct').length;

		for (var i = 0; i === itemBlock; i++){

			var subTitle = productSubName.clone();

			 newDivProduct
			 .addClass('priceTitleProduct')
			 .append(clonePrice)
			 .prepend(subTitle)
			 .insertBefore('.product-detail');

			 soldout
			 .addClass('soldMobile')
			 .insertBefore('.product-variations');

			  comingsoon
			 .addClass('soldMobile')
			 .insertBefore('.product-variations');

			  btnalertshow
			 .addClass('soldMobile')
			 .insertBefore('.product-variations');
		}
	}
};
// add or delete quantity quickview

Maje.product.quantity = function(){
	if($('.quickview').length){
		getQuantity = function(element){
			that = $(element);

			parentQty = that.closest('#QuickViewDialog').find('.quantity');

			nbQty = parentQty.find('#Quantity').val();
			if($(element).hasClass('lessQuantity') && parentQty.find('#Quantity').val() != 1){

				nbQty--;

				parentQty.find('#Quantity').val(nbQty);
			}else if($(element).hasClass('plusQuantity')){

				nbQty++;

				parentQty.find('#Quantity').val(nbQty);
			}
		}

		$('.quickview' ).on('click', '.controlQuantity', function(e){
			e.preventDefault();
			e.stopPropagation();
			getQuantity(this);

		});
	}
}
Maje.product.onResize = function(){
	var widthResize = 767;
	$(window).resize(function(){
		if($(window).width() > widthResize){
			return "";
		}else{
			Maje.product.cloneElement();
		}
	});
};
Maje.product.zoomMobile = function (triggerZoom){
	var viewImg = '.imgProduct';
	$(viewImg).each(function(){
		for (var i = 0; $(this).find(triggerZoom).length === 0; i++){
			var buttonZoom = $(document.createElement('a')).addClass('buttonZoom').text('zoom +');
			buttonZoom.appendTo($(this))
			.css({
				'position' : 'absolute',
				'z-index' : '1',
				'top': '50%',
				'left' : '50%',
				'text-align' :'center',
				'margin-left' : -($(this).find(triggerZoom).outerWidth()/2)
			})
			;
		}

	});

	$(document).on('click tap touchstart', triggerZoom, function(e){
		e.preventDefault();
		// on verifie si la div exist qu sein de la page
		for (var i = 0; $('.zoomBlock').length === 0; i++){
			var urlImg = $('.productlargeimgdata').data("hiresimg").split('|');
			var btnClose = $(document.createElement('a')).addClass('closeZoom').text("");
			var contentZoom = $(document.createElement('div')).addClass('zoomBlock');
			var indexImgZoom = $(this).closest('.slideImg').index();
			var createImg = $(document.createElement('img')).attr('src' , urlImg[indexImgZoom]);

			contentZoom
			.css('height' , $(document).height())
			.append(btnClose)
			.append(createImg)
			.appendTo($('body'))
			.show();

		}
		var documentBody = (($.browser.chrome)||($.browser.safari)) ? document.body : document.documentElement;
		$(documentBody).stop().animate({
			scrollTop : $('#wrapper').offset().top
		},'1000');

	});

	$(document).on('click','.closeZoom', function(e){
		e.preventDefault();
		$(this).closest('.zoomBlock').remove();
	});
};

Maje.product.tooltip = function(){
	var btn = '.swatches.size .unselectable .swatchanchor';
	var classTooltip ='.hoverTooltip';
	var classToolError = '.toolError';
	var popinUnvailable = '.contentProductPop';

	function setUpContentProductPop() {

		// on clone les éléments puis nous les déplaçons au sein de la popin
		$('.contentProductPop .product-name').prependTo('.contentProductPop #product-content');
		$('.contentProductPop .textAlert').prependTo('.contentProductPop .product-col-2');
		if($(window).width() < 767){
		  $('#comingsoon').prependTo('.product-variations');
		}
		$('.contentProductPop .product-tabs, .contentProductPop .productReturnNextPrev').remove();
		$('.contentProductPop .product-add-to-cart, .contentProductPop .priceTitleProduct').remove();
		$('.contentProductPop .product-actions').remove();
		$('.contentProductPop .lastProductsSeenPush').remove();
		$('.contentProductPop #send-to-friend-main').remove();
		$('.contentProductPop .sizeguide').remove()
		$('.contentProductPop .lookbookPush, .contentProductPop .cross-sell').remove()
		$('.contentProductPop a.zoomMain').remove();
		$(document).on('click', '.contentProductPop a.thumbnail-link, .contentProductPop a.product-image', function(e) {e.preventDefault(); return false;});
		$('.contentProductPop img').css('cursor','default');
		$('.contentProductPop').find('.swatches.size .emptyswatch:not(.unselectable)').remove();
		$('.contentProductPop .unselectable').removeClass('unselectable').css('cursor', 'pointer');
	}

	$(document).on('hover,click', btn, function(e){
		e.preventDefault();
		$(classTooltip).remove();
	}).on('mouseleave', classTooltip , function(e){
		e.preventDefault();
		$(this).remove();
	}).on('mouseleave', btn, function(e){
		setTimeout(function(){
			if($(classTooltip).is(':not(:hover)')){
				$(classTooltip).hide();
			}
		}, 500);
	});


	var initBtnAlert = false;

	$(document).on('click', '.product-add-to-cart .openProduct', function(e){
		e.preventDefault();
		if ($(window).width() < 768) {
			var sizeVariations = $('.details-middle-container .product-variations');
			sizeVariations.addClass('open');
			$('.details-middle-container .divAlert, .details-middle-container .soldoutDiv').addClass("pdpActiveAlert");
			$(document).find('.close-size').on('click', function(e){
				sizeVariations.removeClass('open');
				$('.details-middle-container .divAlert, .details-middle-container .soldoutDiv').removeClass("pdpActiveAlert");
			});
		} else {
			if($('.openProduct').hasClass('formEmail-disabled')){
				var errortext = $('ul.swatches.size').attr('notselected-text');
				if(!$('.soldoutDiv').find('.hoverTooltip2').length > 0){
					$(document.createElement('div')).addClass('hoverTooltip2').text(errortext).prependTo('.soldoutDiv');
				}
			}else{
				$('.details-middle-container .divAlert, .details-middle-container .soldoutDiv').addClass("pdpActiveAlert");
			}
		}
	});

	$(document).on('mouseleave', '.product-tile.out-of-stock', function(e){
		$(this).removeClass("activeAlert");
	});

	//verification Email
	Maje.product.mail();

	// change hauteur
	setHeight();

	$(window).resize(function () {
		if ($(window).width < 767) {
			if ($('.product-set-item').length > 1) {
				$('.add-sub-product').removeAttr('style');
			}
		} else {
			setHeight();
		}
	});

	function setHeight() {
		if ($(window).width < 767) {
			$('.product-set-item').each(function () {
				var heightBlock = $(this).find('.product-name').height();
				var heightName = $(this).find('.product-variations').height();
				$(this).find('.add-sub-product').height(heightBlock + heightName);
			});
		}
	}
};
Maje.product.moveElementPopinProduct = function(){
	// nous deplacons la div qui contient l'image du produit lorsque nous sommes en mobile

	var detectWindowMobile = $(window).width() < 767;
	var detectWindowDesktop = $(window).width() > 767;
	var lengthDiv = $('.contentProductPop .product-primary-image').length;
	var dataAfter =  $('.contentProductPop .product-primary-image').attr('data-after');

	$(window).width() < 767 ? heightPop = '' :  heightPop = $('.contentProductPop .product-col-2').outerHeight();
	  // on centre la popin au centre de la page.
		$('.contentProductPop').css({
			'height' : heightPop,
			'top' : $(window).width() < 767 ? '65px' : '',
			'margin-left' : -($('.contentProductPop').outerWidth() / 2),
			'margin-top' : $(window).width() < 767 ? '0' : -(heightPop/2)
		});

	 if(detectWindowMobile && lengthDiv == 1 && dataAfter == undefined ){
		  $('.contentProductPop .product-primary-image').insertAfter($('.contentProductPop .textAlert'));
		  $('.contentProductPop .product-primary-image').attr('data-after', 'true');
	  }else if(detectWindowDesktop && lengthDiv == 1 && dataAfter == "true"){
		  $('.contentProductPop .product-primary-image').appendTo($('.contentProductPop .product-image-container'));
		  $('.contentProductPop .product-primary-image').removeAttr('data-after');
	  }
};

// chargement Ajax des filtres de la page ListProduit

Maje.product.addScrollOnFilter = function() {
	// This function is made to add a scroll on filter when search result gives small number of products making the window smaller than filter
	var wrapperHeight = $('#wrapper .search-result-content').height();
	var filterHeight = $('.blckFilter.filterResult').find('.contentFilter').height();
	if ($(window).width() > 1125) {
		if ($('.blckFilter.filterResult').hasClass('active')) {
			if (wrapperHeight < filterHeight) {
				$('.blckFilter.filterResult').find('.contentFilter').addClass('addScroll');
			}
		} else {
			if ($('.blckFilter.filterResult').find('.contentFilter').hasClass('addScroll')) {
				$('.blckFilter.filterResult').find('.contentFilter').removeClass('addScroll');
			}
		}
	}
};

Maje.product.filters = function() {
	// Convenience function, use to retrieve a parameter value in the current url query string (ex : getParameterByName('q') )
	var getParameterByName = function(name) {
		name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		results = regex.exec(location.search);
		return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
	};

	// refinement UI
	$('.refinement-container .refinement').addClass(function(){
		var itemNum = $(this).find('ul li').length;
		return itemNum > 7 ? 'two-col' : 'one-col';
	});

	// $('.contentFilter .textDeleteFilter').on('click', function(e) {
		//e.stopPropagation();
	// });

	$(document).ready(function(){
		if(($(".infosProduct .product-pricing #soldout").length == $(".infosProduct .product-pricing").length) && $(".pagination ul").length == 0){
			$(".refinement .linkAvailable.InStock").css({
				"color": "#dbdbdb",
				"cursor": "default",
				"text-decoration": "none"
			});
		}

		function resizeBloc() {
			if (window.innerWidth > 1024) {
				$('.img-asset').height(($('#search-result-items .grid-tile').outerHeight()*2 + 28));
			}
			else if (window.innerWidth < 1023) {
				$('.img-asset').height(($('#search-result-items .grid-tile').outerHeight()*2 + 18));
			}
			else {
				$('.img-asset').height('auto');
			}
		}

		$(window).resize(function() {
			resizeBloc();
		}).resize();

	});

	function tagFilterInit() {
		var filterItem = $('.filter-marker');
		if (filterItem.length) {
			filterItem.closest('.tag-filters').addClass('has-filter');
			filterItem.each(function() {
				$(this).closest('.refinement').addClass('has-filter');
			});
		}
	}

	tagFilterInit();

	$('.tag-filters .refinement:not(.category-refinement) ul li a, .contentFilter .refinement:not(.category-refinement) ul li a, .subFilterTri ul li a, a.textDeleteFilter').on('click', function(e) {
		// Prevent the default behaviour of the link
		e.preventDefault();

		// Check if the cliked link is selectable.
		// If not, exit the function
		if($(this).parent().hasClass('unselectable')) return false;
		var isTagFilter = $(this).closest('.tag-filters').length? true:false;
		var link = $(this).attr('href');
		var filteredLink = link;
		var queryString=link.split('?')[1] || '';
		var isOnlySelectedFilter = $(this).closest('.contentFilter').find('li.selected').length == 1 ;

		var isSelected = $(this).parent().hasClass('selected');

		if($(this).hasClass("linkAvailable")){
			if(link.indexOf("format=ajax") < 0){
				link += "&format=ajax";
			}

			if($(this).hasClass("all")){
				if(link.indexOf("&opOnly=false") >= 0){
					link = link.replace("&opOnly=false", "");
				}else{
					link = link.replace("&opOnly=true", "");
					link += "&opOnly=false";
					var parameterInStock = false;
				}
			}else{
				if(($(".infosProduct .product-pricing #soldout").length == $(".infosProduct .product-pricing").length) && $(".pagination ul").length == 0){
					return false;
				}
				if(link.indexOf("&opOnly=true") >= 0){
					link = link.replace("&opOnly=true", "");
					var parameterInStock = false;
				}else{
					link = link.replace("&opOnly=false", "");
					link = link.replace(/start=([^&]$|[^&]*)/i, "start=0");
					link += "&opOnly=true";
					var parameterInStock = true;
				}
			}
		} else{
			var parameter = "";

			//add some other paremeter needed in the request according to some value
			if($(".parameterInStock").val() == "true" && !$(this).hasClass("textDeleteFilter")){
				var parameterInStock = true;
				parameter = "&opOnly=true";
			}
			if(!$(this).hasClass("packshotButton") && $("#currentPackView").val() == "true"){
				parameter = parameter + "&showPack=true";
			}
			if(!$(this).hasClass("packshotButton") && $("#currentFrontView").val() == "true"){
				parameter = parameter + "&showPack=false";
			}

			//build the fetch or reset link
			if ($(this).hasClass('textDeleteFilter')) {
				//if the reset is comming from a filter builded by a search request (search input)
				//we must take the searched value in consideration before reseting the filter
				if(getParameterByName('q').length > 0 || getParameterByName('cgid').length > 0){
					link = window.location.href + '&format=ajax';
				} else {
					link = $(this).attr('href');

					if (link.indexOf('?') > -1) {
						link += '&format=ajax';
					} else {
						link += '?format=ajax';
					}

					link += parameter;
				}

			} else {
				//build the fetch link
				if(isSelected && queryString.length == 0){
					link = $(this).attr('href') + '?format=ajax'+parameter;
				}
				else if ( !(isSelected && isOnlySelectedFilter) || getParameterByName('q').length > 0 || queryString.length > 0) {
					link = $(this).attr('href') + '&format=ajax'+parameter;
				} else {
					link = $(this).attr('href') + '?format=ajax'+parameter;
				}
			}
		}

		var secondClass = $(this).closest('.blckFilter').hasClass('filterResult') ? '.filterResult' : '.filterTri';
		var url = new URL(link);
		$(document).trigger("plp.filter", [url.searchParams.get("prefn1"), url.searchParams.get("prefv1"), link]);
		// Build the ajax call
		app.progress.show($("#main").find(".search-result-content"));
		$.ajax({
			url : link,
			success : function(response) {
				// Traitement du retour de la requete ajax
				$('#main').html(response);
				Maje.product.filters();
				Maje.product.pricePromoAlign();
				Maje.product.quickActions();
				app.product.tile.init();
				Maje.product.addScrollOnFilter();
				if(parameterInStock === true){
					$(".parameterInStock").val(true);
					$(".linkAvailable.all").removeClass("selected");
					$(this).addClass("selected");
				}
				else if(parameterInStock === false){
					$(".parameterInStock").val(false);
				}
				if($(".infosProduct .product-pricing #soldout").length == $(".infosProduct .product-pricing").length){
					$(".refinement .linkAvailable.InStock").css({
						"color": "#dbdbdb",
						"cursor": "default",
						"text-decoration": "none"
					});
				}
				if (!isTagFilter) {
					$('.blckFilter'+secondClass).find('.btnTri').trigger('click');
				}
				tagFilterInit();
				app.searchsuggest.changeGrid();
				$('html, body').stop().animate({ scrollTop: top }, 100, function() {
					$(window).trigger('scroll');
				});
				// change url after filtered
				window.history && history.replaceState({}, '', filteredLink);
			},

			error : function() {
				// TO DO : Traitement d'un eventuel cas d'erreur
			},

		}).done(function() {
			app.progress.hide();
			var endLoadingProduct = $('.search-result-content.visible').find('div[data-loading-state="unloaded"]').length;
			var infinitScrollMode = $('.infinite-scroll-placeholder').length;
			if (endLoadingProduct == 0 && infinitScrollMode.length == 1) {
				$('.bottom-of-pages').addClass('showMe');
			}
		});
	});

	//add class to position show all and scrollToTop button
	if ( $('.pagination.large').length ){
		$('.search-result-options.bottomPaginate').addClass('largePage');
	}
}

Maje.product.pricePromoAlign = function() {
	var productPrice = $(".search-result-content .product-pricing");

	if(productPrice.length){
		productPrice.each(function(){

			if($(this).find(".reducePercent").length){
				$(this).addClass("product-pricing-promo");
			}

		});
	}
}

Maje.product.mail = function (){
	var inputVm ='.alertEmail';
	var inputSubmit = '.btnAlert';


	// nous supprimons le message d'erreur au focus
	$(document).on('focus click', '.alertEmail, .divAlert .toolError, .btnAlert', function(){
		$('.divAlert .errorEmail').remove();
		$('.labelEmail').removeClass('errorEmailActive');
		$('.alertEmail').removeClass('errorEmailActive');
	});

	// Click on the validation button.
	$(document).on('click', inputSubmit, function(){

		var quickActions = $(this).parents(".quick-actions").length;
		var form, result;

		if(quickActions > 0){
			form = $(this).parents(".quick-actions").find(".formEmail");
			result = $(this).parents(".quick-actions").find(".alert-result span");
			$(this).parents(".quick-actions").addClass("add-alert-in-stock");
		} else {
			form = $(".formEmail");
			result = $(".alert-result span");
		}


		$(document).trigger('replenishment.request');

		// Si il
		var classToolError = '.toolError';
		$(classToolError).remove();
		// on verifie si le format d'Email est correct, dans ce cas nous supprimons la tooltip Error si elle est présente.
		var regxp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
		var emailToAlert = $(this).parent().find('.alertEmail').val();
		if (emailToAlert.match(regxp)  ) {
			$(classToolError).remove();
			var variationParams = '';

			if($(".search-result-items").length == 0){
				$(this).closest('.pdp-main').last().find('.selected a.swatchanchor').each(function() {
					variationParams += '&' + $(this).data('variationparameter');
				});
				$.ajax({
					url: app.urls.registerProductAlert,
					data: 'email=' + emailToAlert + '&pid=' + $('input#pid').val() + variationParams,
					success : function(response) {
						if(response.success) {
							form.css('display', 'none');
							result.html(response.message).addClass('activeResult');
						}
					}
				});
			}
			else {
				$.ajax({
					url: app.urls.registerProductAlert,
					data: 'email=' + emailToAlert + '&pid=' + $('.pidToAlert').val() + variationParams,
					success : function(response) {
						if(response.success) {
							form.css('display', 'none');
							result.html(response.message).addClass('activeResult');
						}
					}
				});
			}
		} else {
			// nous créeons l'appel de la tooltip pour le Desktop, dans l'autre cas elle est destiné au mobile

			var label = $(this).closest('.formEmail').find('.alertEmail');

			// check if the label exists
			if(label.length) {

				// check if the label has the class 'errorEmailActive'.
				// if it doesn't, create the div that will display the error message and set the textError.

				if (! label.hasClass('errorEmailActive')) {
					label.addClass('errorEmailActive');
					var toolError = $(document.createElement('div')).addClass('errorEmail');
					var textError = app.resources.INVALID_EMAIL;
					var middleHeightTool = $(this).closest('body').find(classToolError).outerHeight()/2;
					var middleHeightButton = $(this).prev('.alertEmail').outerHeight()/2;
					var pos = 10;
				}
			}

			// nous créeons l'appel de la tooltip pour le Desktop, dans l'autre cas elle est destiné au mobile
			$(this).closest('.formEmail').append(toolError);
			$(this).closest('.formEmail').find(toolError).text(textError);

		}
	});
};

Maje.product.sendtofriendpopin = function() {
	function sendTo() {

		var popinUrl = $('.sendPopin').attr("data-href");

		$.ajax({
			url : popinUrl
		})
		.done(function (response) {

			var contentresponse = $(response).find('#send-to-friend-form');

			$("#send-to-friend-main").dialog({
				width: ($(window).width() >= 767 ? 720 : 310),
				dialogClass: "sendTo",
				closeText: "",
				position: {my: "top", at: "center", of: "#header"},
				draggable: false,
				close: function() {
					$('.greyLayer, form#send-to-friend-form').remove();
				}
			});

			$('body:not(:has(div.greyLayer))').append('<div class="greyLayer"></div>');
			$('.formContainer:not(:has(form#send-to-friend-form))').append(contentresponse);

			if ($(window).width() < 767) {
				$('.sendTo')
				.find('img.product')
				.prependTo('.sendTo .send-to-friend-product');
			}
		})
		.fail(function () {

		})
		.always(function () {

		});

		return false;
	};

	$(document).on('click', '.sendPopin', function() {
		sendTo();
	});

	$(document).on('click', '.required', function(e) {
		if ($(this).closest('.form-row.required').length) {
			$(this).parent().removeClass('error');
		} else {
			$(this).parent().removeClass('error');
			$(this).find('span.error-message').remove();
		}
	});

	$(document).on('click', '.send-button', function(e) {

		$('div.sendTo').find('.error-message').remove();

		var globalvalid = true;

		$('div.sendTo div.required').each( function() {

			var inputvalid = true;
			var input = $(this).find('input[type="text"]');

			if (input.val().length <= 0) {

				inputvalid = false;

				if (!$(this).hasClass('error')) {
					$(this).addClass('error');
					$(this).append('<span class="error-message" onclick="$(this).remove();">'+$(this).attr('data-required-text')+'</span>');
				}

			} else if (input.hasClass('email')) {

				var emailvalue = input.val();
				var regex = {email: /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/};
				inputvalid = regex.email.test($.trim(emailvalue));

				if (!inputvalid) {


					$(this).append('<span class="error-message" onclick="$(this).remove();">'+app.resources.EMAIL_ERROR+'</span>');

				} else {

					if (input.hasClass('confirmfriendsemail')) {
						var inputfriendemail =  $('div.sendTo').find('input.friendsemail');
						var friendemail = inputfriendemail.val();

						if(emailvalue != friendemail) {
							inputvalid = false;

							$(this).append('<span class="error-message" onclick="$(this).remove();">'+app.resources.EMAIL_CONFIRM_ERROR+'</span>');
						}
					}
				}
			}

			if (inputvalid && $(this).hasClass('error')) {
				$(this).removeClass('error');
				$(this).find('.error-message').remove();
			}

			if (!inputvalid) {
				globalvalid = false;
			}
		});

		if (globalvalid) {
			return true;
		} else {
			return false;
		}
	});
};

Maje.product.showHidebtnZoom = function(){
	var eClass = {
			mainImg : '.zoomPup',
			buttonZoom : '.zoomMain'
	};

	$(document).on('mouseleave', eClass.mainImg, function(event){
		event.preventDefault();
	}).on('mouseover', eClass.mainImg, function(event){
		event.preventDefault();
		$(eClass.buttonZoom).hide();
	}).on('mouseenter', eClass.mainImg, function(event) {
		$('.hoverTooltip, .hoverTooltip2').remove();
	});

	$(document).on('click touchstart', eClass.buttonZoom, function(e){
		e.preventDefault();
	// if((navigator.userAgent.match(/iPad/i)) && (navigator.userAgent.match(/iPad/i)!= null)){
		var urlImg = $('.productlargeimgdata').data("hiresimg").split('|');
		var btnClose = $(document.createElement('a')).addClass('closeZoom').text("");
		var contentZoom = $(document.createElement('div')).addClass('zoomBlock');
		var indexImgZoom = $(this).closest('.product-image-container').find('.thumb.selected').index();
		var createImg = $(document.createElement('img')).attr('src' , urlImg[indexImgZoom]);

		contentZoom
		.css({
			'height' : $(document).height(),
			'z-index' : '40000'
		})
		.append(btnClose)
		.append(createImg)
		.prependTo($('body'))
		.show();
		;
	//}
	});

	// we close popin of zoom
	$(document).on('click touchstart','.closeZoom', function(e){
		e.preventDefault();
		$(this).closest('.zoomBlock').remove();
	});
};
Maje.product.jqZoom = function(){
	var el = {
			zoomPad : '.product-image',
			zoomWindow : '.zoomWindow',
			zoomPup : '.zoomPup'
	};
};
Maje.product.activeZoom = function(cssClass){
	  if(typeof window.orientation !== 'undefined'){
	   $(cssClass).css('display','block');
	  }else{
	   $(cssClass).css('display','none');
	  }
};
Maje.product.disable = function(){
	var el = {
		hoverTool2 : '.hoverTooltip2',
		hoverTool3 : '.hoverTooltip3',
		hoverTool4 : '.hoverTooltip4'
	};
	$(document).on('click tap touchstart', ":not(.hoverTooltip2.active)", function(){
		if($(el.hoverTool2).hasClass('active')){
			$(el.hoverTool2).remove();
		}
	});
	$(document).on('click tap touchstart', ":not(.hoverTooltip3.active)", function(){
		if($(el.hoverTool3).hasClass('active')){
			$(el.hoverTool3).remove();
		}
	});
	$(document).on('click tap touchstart', ":not(.hoverTooltip4.active)", function(){
		if($(el.hoverTool4).hasClass('active')){
			$(el.hoverTool4).remove();
		}
	});
};

Maje.product.displayVideo = function () {
	$('#thumbnailsvideo.video').on({
		click: function(e) {
			e.preventDefault();
			var url_video = $(this).find('img').data('video').url;
			getMyVideo(url_video);
		}
	});

	$('.product-thumbnails li').on('click',function(){
		var videoCont = $('#myVideo');
		if (videoCont.length){
				videoCont.remove();
		}
	});
};

Maje.product.quickActions = function() {
	var productTile = $('.product-tile .thumb-link');
	var ajaxCall;
	productTile.on('mouseover', function(e) {
		e.preventDefault();
		if(ajaxCall && ajaxCall.readyState != 4) {
			ajaxCall.abort();
			$(this).children('.infosProduct').first().css("opacity", "initial");
		}
		var that = $(this).closest('.product-tile');
		var pid = that.attr('data-itemid');
		var url = app.util.appendParamsToUrl(app.urls.getQuickActions, {
				pid  : pid,
				vpid : $(that).children('*[id^="vpid-"]').first().val()
			});
		ajaxCall = $.ajax({
			url		: url,
			method 	: "POST"
		}).done(function(data) {
			try {
				var result = JSON.parse(data);
			} catch(e) {
				$(that).children('*[id^="quick-actions-ajax-"]').first().html(data);
			}
		});
	});
}

Maje.home.vimeoplayerjs = function(){
	$('[data-controls-for-video]').each(function() {
		initPlayer($(this));
	});

	function initPlayer($controls) {
		var selector_for_players = $controls.data('controls-for-video'),
			$vim_play = $controls.find('.vim_play'),
			$vim_mute = $controls.find('.vim_mute'),
			$vim_pauseclik = $controls.closest('.video-wrapper');

		$( selector_for_players ).each(function() {
			var player = this;

			$(player).on('pause', function()  { $vim_play.addClass('active') });
			$(player).on('play', function()   { $vim_play.removeClass('active') });
			$(player).on('volumechange', function() {
				true === player.muted ? $vim_mute.addClass('active') :  $vim_mute.removeClass('active');
			});
		});

		$.merge($vim_play, $vim_pauseclik).on('click', function (e) {
			e.stopPropagation();
			e.preventDefault(); togglePlayback(selector_for_players);
		});
		$vim_mute.on('click', function (e) {
			e.stopPropagation();
			e.preventDefault(); toggleSound(selector_for_players);
		});
	}

	function togglePlayback(selector_for_players) {
		$( selector_for_players ).each(function() {
			var player = this;
			true === player.paused ? player.play() : player.pause();
		});
	}

	function toggleSound(selector_for_players) {
		$( selector_for_players ).each(function() {
			var player = this;
			player.muted = !player.muted;
		});
	}
};

resizeVideo = function(){

		if($(window).width() < 767 && $('#myVideo').length > 0 ) {
			jwplayer().resize(320,200);
			if($('.product-primary-image').next('#myVideo').length !== 0){
				$('#myVideo').remove();
			}

		} else if($(window).width() > 767 && $('#myVideo').length > 0){
			jwplayer().resize(460,472);
			if($('#video-dialog').find('#myVideo').length !== 0){
				$('.ui-dialog-titlebar-close').trigger('click');
			}
		}
	};
getMyVideo = function(urlvideo){
		var idMyVideo = $(document.createElement('div')).attr('id', 'myVideo');
		if ($(window).width() < 767){
			idMyVideo.appendTo($('#video-dialog'));
		}else{
			idMyVideo.insertAfter('.product-primary-image');
		}

		var heightPlayer, widthPlayer;

		if($(window).width() < 767 && $('#myVideo').length > 0 ) {
			  heightPlayer = 180;
			  widthPlayer = 310;
			} else if($(window).width() > 767 && $('#myVideo').length > 0){
				heightPlayer = 472;
				 widthPlayer = 460;
			}

		jwplayer("myVideo").setup({
			//file: "${URLUtils.staticURL('/upload/Costume_604327_9020_V3.mp4')}",
			file: urlvideo,
			width: widthPlayer,
			height: heightPlayer,
			autostart: true,
			mute: true,
			controls: false
		});

		if($(window).width() < 1080){
			jwplayer().setControls(true);
		}

		$(window).on({
			resize : function(){
				if(!$('html').hasClass('ie8')){
					resizeVideo();
				}
			}
		});
	};
	Maje.footer.slideAssurance = function(){
		var widthW = 767;
		var blockToSlide = $('.reassuranceFooter');
		var itemVisible = $('.reassuranceFooter').find('li').not(':hidden');
		var lengthItem = itemVisible.length;
		var appendUl = $('<ul class="tapItem"></ul>');
		var classAppendUl = $('.tapItem');

		if($(window).width() < 767){
			var existUl = classAppendUl.length === 0;
				if(existUl){
					appendUl.insertAfter(blockToSlide);

				itemVisible.each(function(){
					var posItem = $(this).prev('li').outerWidth();
					$(this).css({
						'width' : $('#footer').outerWidth()
					});
				});

				blockToSlide.css('width', lengthItem * itemVisible.outerWidth());
				blockToSlide.addClass('swiper-wrapper').find('li').addClass('swiper-slide');

				var mySwiperFooter = $('.footerReassu').swiper({
					mode:'horizontal',
					pagination: '.tapItem',
					paginationClickable:false,
					loop: false
				});
			}
		}else{
			classAppendUl.remove();
			itemVisible.removeAttr('style');
			blockToSlide.removeAttr('style');
			blockToSlide.removeClass('swiper-wrapper').find('li').removeClass('swiper-slide');
			$('.reassuranceFooter').removeAttr('style');
		}
	};

	Maje.footer.menuHover = function(){

		var $wrapper = $('#wrapper');
		var $footMenu = $('.menuFooter');
		var menuExpanded = false;
		var speedAnimate = 500;
		var height = 115;
		var heightExpan = 305;

		$footMenu.find("ul li .item-toggle").on("click", function() {

			if($(window).width() > 767){
				var that = $(this);
				var parentEl = $(this).parent("li");

				if(menuExpanded === false && !$(this).hasClass("hover")){
					var top = $(document).scrollTop() + heightExpan;
					$('html, body').stop(true).animate( { scrollTop: top }, speedAnimate );
					$wrapper.stop(true).animate( { 'padding-bottom' :  height + heightExpan }, speedAnimate );
					$footMenu.stop(true).animate({paddingBottom: heightExpan}, speedAnimate, function() {
						$footMenu.find("ul li").removeClass("hover");
						parentEl.addClass("hover");
						menuExpanded = true;
					});
				}else if(menuExpanded === true && !parentEl.hasClass("hover")){
					$footMenu.find("ul li").removeClass("hover");
					parentEl.addClass("hover");
				}else if(parentEl.hasClass("hover")){
					closeFooterMenu();
				}
			}
		});

		$footMenu.find("ul li .item-toggle").on("mouseenter", function() {
			if($(window).width() > 767){
				var that = $(this);
				var parentEl = $(this).parent("li");

				if(menuExpanded === true){
					$footMenu.find("ul li").removeClass("hover");
					parentEl.addClass("hover");
				}
			}
		});

		$(document).on("mouseup", function(e){
			var condition = $(e.target).parents("#footer").length;

			if(!condition){
				closeFooterMenu();
			}
		});


		function closeFooterMenu() {
			if (menuExpanded === true) {
				var $wrapper = $('#wrapper');
				$footMenu.find("ul li").removeClass("hover");
				$footMenu.stop(true).animate({paddingBottom: 0}, speedAnimate);
				$wrapper.stop(true).animate({'padding-bottom' : height}, speedAnimate, function() {
					$wrapper.removeAttr("style");
					$(window).scroll();
				});
				menuExpanded = false;
			}
		}

		$(window).on("resize", function() {
			closeFooterMenu();
		});
	};


Maje.home.bindMove = function(){
	Maje.home.moveNews();
};

//function move block Newsletter
Maje.home.moveNews = function(){
	var el = {
		news : '.showNewsletterSubscription',
		home : '.pt_storefront',
		push : '.smallPushes',
		homepage :'.homePage'
	};

	// we move block of newsletter from homepage

		if($(window).width() < 767){
			if (window.location.search.indexOf('newsletter=1') >= 0) {
				$('div.footerNewsletter').addClass('active');
			}
			if ($(el.homepage).children(el.news).length === 0){
				$(el.home)
				.find(el.news)
				.clone()
				.insertAfter(el.push);
			}
		}else{
	// we delete block of newsletter from homepage
			$(el.homepage)
			.find(el.news)
			.remove();
		}
};
Maje.home.resize = function(){
	$(window).resize(function(){
		Maje.home.moveNews();
	});
};

// Vimeo Player
Maje.home.VimeoPlayer = (function () {
	function VimeoPlayer(playerInDom, isBigPush) {
		this.isBigPush = isBigPush;
		this.playerInDom = playerInDom;
		this.vimeoPlayer = {
			hasResult : false,
			isDataPopulated : false,
			vimeoDataQuery : null,
			vimeoPlayerResponse : null,
			apiUrl:'https://vimeo.com/api/oembed.json'
		};
	}

	VimeoPlayer.prototype.getVimeoDataFromMarkup = function () {
		var container = this.playerInDom;
		var videoUrl = container.length > 0 ? container.attr("data-url") : "";
		var auto = container.length > 0 ? (container.attr("data-autoplay")=="true") : false;
		var data = {
			url: videoUrl,
			height : this.isBigPush ? 410 : 250,
			width : this.isBigPush ? 960 : 470,
			color : "cfff02",
			title:false,
			portrait:false,
			autoplay:auto,
			byline:false
		};

		this.vimeoPlayer.vimeoDataQuery = data;
		this.vimeoPlayer.isDataPopulated = true;
	};

	VimeoPlayer.prototype.disableAutoplay = function () {
		this.vimeoPlayer.vimeoDataQuery.autoplay=false;
	};

	VimeoPlayer.prototype.getVimeoPlayer = function () {
		var root = this;
		if(this.vimeoPlayer.isDataPopulated){
			$.ajax({
				dataType: 'jsonp',
				crossDomain: true,
				data: root.vimeoPlayer.vimeoDataQuery,
				url: root.vimeoPlayer.apiUrl
			})
			.done(function(data){
				root.vimeoPlayer.vimeoPlayerResponse = data;
				if(root.vimeoPlayer.vimeoPlayerResponse && root.vimeoPlayer.vimeoPlayerResponse.html){
					root.vimeoPlayer.hasResult = true;
					if(typeof(root.injectVimeoPlayer) === "function"){
						root.injectVimeoPlayer(root.playerInDom, root.vimeoPlayer);
					}
				}
			})
			.fail(function(){});
		}
	};

	// CallBack
	VimeoPlayer.prototype.injectVimeoPlayer = function (playerInDom, vimeoPlayer) {
		if(vimeoPlayer.hasResult){
			playerInDom.html(vimeoPlayer.vimeoPlayerResponse.html);
		}
	};

	VimeoPlayer.prototype.displayVimeoPlayer = function () {
		if(this.playerInDom.length > 0){
			this.getVimeoDataFromMarkup();
			var root = this;

			$(window).resize(function(){

				if($('body').width() <= 767){
					if(root.isBigPush)
					{
						root.playerInDom.css({width:'310px', height:'205px'});
						root.vimeoPlayer.vimeoDataQuery.height = 205;
						root.vimeoPlayer.vimeoDataQuery.width = 310;
					}
					else
					{
						root.playerInDom.css({width:'153px', height:'126px'});
						root.vimeoPlayer.vimeoDataQuery.height = 126;
						root.vimeoPlayer.vimeoDataQuery.width = 153;
					}
				}
				else
				{
					if(root.isBigPush)
					{
						root.playerInDom.css({width:'960px', height:'410px'});
						root.vimeoPlayer.vimeoDataQuery.height = 410;
						root.vimeoPlayer.vimeoDataQuery.width = 960;
					}
					else
					{
						root.playerInDom.css({width:'470px', height:'250px'});
						root.vimeoPlayer.vimeoDataQuery.height = 250;
						root.vimeoPlayer.vimeoDataQuery.width = 470;
					}
				}

				root.disableAutoplay();
				root.getVimeoPlayer();
			});

			if($('body').width() <= 767){
				if(this.isBigPush)
				{
					this.playerInDom.css({width:'310px', height:'205px'});
					this.vimeoPlayer.vimeoDataQuery.height = 205;
					this.vimeoPlayer.vimeoDataQuery.width = 310;
				}
				else
				{
					this.playerInDom.css({width:'153px', height:'126px'});
					this.vimeoPlayer.vimeoDataQuery.height = 126;
					this.vimeoPlayer.vimeoDataQuery.width = 153;
				}
			}
			else
			{
				if(this.isBigPush)
				{
					this.playerInDom.css({width:'960px', height:'410px'});
					this.vimeoPlayer.vimeoDataQuery.height = 410;
					this.vimeoPlayer.vimeoDataQuery.width = 960;
				}
				else
				{
					this.playerInDom.css({width:'470px', height:'250px'});
					this.vimeoPlayer.vimeoDataQuery.height = 250;
					this.vimeoPlayer.vimeoDataQuery.width = 470;
				}
			}

			this.playerInDom.show();
			this.getVimeoPlayer();
		}
	};

	return VimeoPlayer;
})();

/*
 * Block that load the video full screen on home page
 *  - Vimeo : Resize and adapt size
 *  - Jw Player : create player and load
 */
Maje.home.videoFullScreen = function(){
	var url_video = $("div#video").data("src-video");
	var vimeo_video = $("iframe[src^='//player.vimeo.com']");
	var _this = this;

	/*
	 * init events
	 */
	this.init = function() {

		if((url_video || vimeo_video) && $(window).width() >= 768 && $(".touch").length === 0){

			_this.hideBackgroundImage();

			if(url_video){
				_this.eventJwPlayer();
			}
			else if(vimeo_video){
				_this.resizeVideo();
			}
		}
	};

	/*
	 * Hide background image on the desktop site
	 */
	this.hideBackgroundImage = function() {
		$("div#image").css('display', 'none');
	}

	/*
	 * Load video with jw player
	 */
	this.eventJwPlayer = function() {

		jwplayer("video_fullscreen").setup({
			file: url_video,
			width: "auto",
			height: "auto",
			autostart: true,
			mute: true,
			controls: false,
			repeat:true,
			mute:true,
			controlbar: 'none'
		});
	};

	/*
	 * Resize video vimeo or youtube to adapte to user screen
	 */
	this.resizeVideo = function() {
		$(window).resize(function() {
			var height = $(window).height() - $("div#header").outerHeight();
			$('.block-homepage-video').css({
				height: height
			})
		}).resize();
	}

	this.init();
}

Maje.checkout.surchageCreditCardErrorMessage = function(){
	$.validator.messages.number = app.resources.NUMBER_ERROR;
}

/*Maje.checkout.onChangePaymentMethod = function() {
	$('input[name*="paymentMethods_creditCard"]').change(function() {
		$(this).closest("form").append('<input type="hidden" name="selectedCardID" value="'+ $(this).val() + '" />');
	});
}*/

Maje.checkout.shieldClicksFinalOrderButton = function() {

	//for Maje the belling page has two submit button final-order-button & final-order-button-dup
	//the final-order-button-dup is only shown when we are on a mobile device

	var submitButton = $('#final-order-button');
	var submitButtonDup = $('#final-order-button-dup');
	var form = submitButton.closest("form");

	// SMCP-5063 :Adding an event handler for the dup button, because even if it is of type submit
	// it is not included in the form, so it won't do anything
	submitButton.on('click', function(e) {
		e.preventDefault();
		form.submit();
	});

	form.submit(function( event ) {
		Maje.checkout.hideShowBlockCreditCardSave(true);

		$(window).trigger('billing.paymentupdate');
		if( form.valid() ) {

			submitButton.prop('disabled', true);
			submitButtonDup.prop('disabled', true);
		}
	});

	$(window).on('payment.cardtype.finished', function(e, data) {
		var selectedCardType = $('input[name$="_paymentMethods_creditCard_type"]:checked').val();
		if (selectedCardType.toLowerCase() == data.cardType.toLowerCase()) {
			form.submit();
		} else {
			var validator = $(form).validate();

			validator.showErrors({
				'dwfrm_billing_paymentMethods_creditCard_number': app.resources.CHECKOUT_PAYMENT_CARD_NUMBER
			});

		}
	});
}

Maje.zoomProduct.bindEvents = function(){
	var el = {
		//imageZoom : '.main-image',
		closeCurrent : '.closeCurrent',
		//imgProduct : '.imgProduct',
		zoomPopin : '.zoomPopin',
		//urlVideo : '.urlVideo a',
		zoomMain : '.zoomMain'
		//zoomAction : '.zoomAction'
	};
	var booleanZoom = false;

	if($(window).width() < 768){
		$(".deskZoom").find('a.zoomMain').removeClass("zoomMain");
	}

	$.fn.zoomProduct = function(){

		element = $(this);
		element.on('click',function(e){

			e.preventDefault();
			if($(this).closest('.thumb').hasClass('urlVideo')){
				e.stopPropagation();
			}

			if($(this).hasClass('zoomAction') || $(this).hasClass('zoomMain')){
				attrType = $(this).attr('class');
			}else{
				attrType = $(this).parent().attr('class');
			}


			booleanZoom = true;
			if(booleanZoom === true){
				removeElementPopin();
				// create popin
				createElementPopin('body');
				// event close popin
				closePopin();
				//getImageList Zoom
				switch (attrType){
					case 'product-primary-image':
						getImageZoom();
						break;
					case 'zoomAction':
						getImageZoom();
					break;
					case 'imgProduct':
						if($(this).hasClass('urlVideo')){
							var url_video = $(this).data('urlvideo');
							getMyVideo(url_video);
						}else{
							getImageZoom();
						}

						break;
					case 'thumb urlVideo':
						var url_video = $(this).data('urlvideo');
						getMyVideo(url_video);
						break;
					case 'zoomMain':
						var imgIndex = $(this).find('img').data('index');
						getImageZoom(imgIndex);
					break;
				}
				booleanZoom = false;
			}
		});


	};

	createElementPopin = function(selectorBody){
		var parentDiv = $(document.createElement('div')).addClass('zoomPopin');
		var closeCurrent = $(document.createElement('div')).addClass('closeCurrent iconClose');

		// create element of Popin
		parentDiv
		.css({
			'height' :$(window).height(),
		})
		.appendTo(selectorBody)
		.append(closeCurrent);
		$('body').addClass('zoomBody');
	}
	closePopin = function(){
		$(el.closeCurrent).on('click tap touchstart', removeElementPopin);
		if ($(window).width() < 767){
			$(document).on('click', '.zoomPopin img', removeElementPopin);
		}else{
			$(document).on('click', '.zoomPopin img, .zoomPopin', removeElementPopin);
		}

	};

	removeElementPopin = function(){
		$('.zoomPopin').remove();
		$('#wrapper').removeAttr('style');
		var metaViewport = $('html').find("meta[name='viewport']");
		var saveViewport = 'width=device-width, user-scalable=yes, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0';
		metaViewport.attr('content', saveViewport);
		$(document).scrollTop(0);
		$('body').removeClass('zoomBody');
	};

	$('.zoomMain').on('click', function(){
		$('.img-product').attr('data-active', 0);
		$(this).find('.img-product').attr('data-active', 1);
	});

	getImageZoom = function(imgIndex){

		 var urlImg;
		if(window.innerWidth > 768){
			urlImg = $('.productlargeimgdata').data("zoomdesktop").split('|');
		} else {
			urlImg = $('.productlargeimgdata').data("zoommobile").split('|');
		}

		var swipopin =  $('<div class="Pop-swiper-container"><div class="swiper-wrapper"> </div></div>'),
			swipopinCtl = $('<a class="arrowPop-left"></a><a class="arrowPop-right"></a>'),
			srcThumb = $('.thumb img');
		swipopinCtl.appendTo(swipopin);
		swipopin.appendTo('.zoomPopin');

		for(var i = 0; urlImg.length > i; i++){
			if (urlImg[i].toLowerCase().indexOf(".mp4") === -1) {
				var createImg = $('<div class="swiper-slide noSwipingClass"><div class="swiper-zoom-container"><img src="'+urlImg[i]+'" id="img'+i+'" alt="sandro"/></div></div>');
 				createImg.appendTo('.zoomPopin .swiper-wrapper');
			}
		}
			var initialIndex = imgIndex !== "undefined" ? imgIndex : 0;

			var PopinSwiper = new Swiper('.Pop-swiper-container',{
				mode:'horizontal',
				loop: false,
				initialSlide :initialIndex,
				slidesPerView : 1,
				watchActiveIndex : true,
				onSlideChangeEnd: function(swiper) {
					 if (swiper.activeIndex === 0) {
						$('.Pop-swiper-container .arrowPop-left').css({display: 'none'});
					} else {
						$('.Pop-swiper-container .arrowPop-left').css({display: 'block'});
					}
					if (swiper.activeIndex === swiper.slides.length - 1) {
						$('.Pop-swiper-container .arrowPop-right').css({display: 'none'});
					} else {
						$('.Pop-swiper-container .arrowPop-right').css({display: 'block'});
					}
				},
				onFirstInit: function(swiper){
					if (swiper.activeIndex === 0) {
						$('.Pop-swiper-container .arrowPop-left').css({display: 'none'});
					} else {
						$('.Pop-swiper-container .arrowPop-left').css({display: 'block'});
					}
					if (swiper.activeIndex === swiper.slides.length - 1) {
						$('.Pop-swiper-container .arrowPop-right').css({display: 'none'});
					} else {
						$('.Pop-swiper-container .arrowPop-right').css({display: 'block'});
					}
				},
				breakpoints: {
					1023: {
						zoom: true
					}
				}
		});


		$('.Pop-swiper-container, #pgPop').on('click', function(event){
			event.stopPropagation();
		});

		$('.zoomPopin .arrowPop-right').on('click', function(event){
			event.stopPropagation();
			PopinSwiper.slideNext();
		});

		$('.zoomPopin .arrowPop-left').on('click', function(event){
			event.stopPropagation();
			PopinSwiper.slidePrev();
		});

	};

	getMyVideo = function(urlvideo){
		var idMyVideo = $(document.createElement('div')).attr('id', 'myVideo');
		var myVideo = "";
		if ($(window).width() < 769 || $('.touch').length){
			idMyVideo.appendTo('.swiper-slide-active');
		}else{
			idMyVideo.appendTo('.product-primary-image');
		}

		if($('#myVideo').length <= 0 && $('#video-dialog').length > 0){
			var myVideo = "video-dialog";
		}else if ($('#myVideo').length > 0){
			var myVideo = "myVideo";
		}

		if($(window).width() < 767) {
			heightPlayer = 200;
			widthPlayer = 320;
		} else if($(window).width() > 767){
			heightPlayer = 466;
			widthPlayer = "69%";
		}

		jwplayer("myVideo").setup({
			file: urlvideo,
			width: widthPlayer,
			height: heightPlayer,
			autostart: true,
			mute: true,
			controls: false
		});

		setTimeout(function() {
			jwplayer().pause();
			jwplayer().setMute(false);
			jwplayer().setControls(true);

			$('.zoomPopin').height($('html').height());

		},1000);
	}

	// create zoom
	$(el.zoomMain).zoomProduct();

	var attrV5 = $(el.imgProduct).find('img[src*="V_5"]').length;
	var existVideo = $('#thumbnails').find('.urlVideo').length;
	var urlVideoMobile = $('#thumbnails').find('.urlVideo').find('a').attr('data-urlvideo');

	if(existVideo){
		if(attrV5){
			$(el.imgProduct).find('img[src*="V_5"]')
			.addClass('urlVideo')
			.attr('data-urlvideo', urlVideoMobile);
		}
	}

	resizeVideo = function(){
		if($(window).width() < 767 && $('#myVideo').length > 0 ) {
		  jwplayer().resize(320,200);
		} else if($(window).width() > 767 && $('#myVideo').length > 0){
		  jwplayer().resize("69%",466);
		}
	}

	$(window).resize(function(){
		if(!$('html').hasClass('ie8')){
			resizeVideo();
		}
	});

	$('.imgProduct').on('click', function(e){
		if ($(window).width() < 767 || $('.touch').length){
			e.preventDefault();
			if ($(this).find('img').data('videourl')){
				$('#thumbnailsvideo a').trigger('click');
			}else{
				$('.zoomMain').trigger('click');
			}

		}
	});

	function getPageScroll() {
		var pageScroll = new Object();
		if (self.pageYOffset) {
			pageScroll.y = self.pageYOffset;
			pageScroll.x = self.pageXOffset;
		} else if (document.documentElement && document.documentElement.scrollTop) {
			pageScroll.y = document.documentElement.scrollTop;
			pageScroll.x = document.documentElement.scrollLeft;
		} else if (document.body) {// all other Explorers
			pageScroll.y = document.body.scrollTop;
			pageScroll.x = document.body.scrollLeft;  
		}
		return pageScroll;
	};

	var isMobile = window.screen.width < 768 ? true : false;
	// Main imgae area
	var imgContainer = $('.product-primary-image').find('.swiper-wrapper').find('.swiper-slide');
	var imgContainer1 = $('.wrapper-product-img-1').find('.img-container');
	var imgContainer2 = $('.wrapper-product-img-2').find('.img-container');
	var videoImageIndex = -1;
	var videoUrl= null;
	for (var index = 0; index < imgContainer.length; index++) {
		var videoUrl = imgContainer.eq(index).find('img').attr('src');
		if (videoUrl.lastIndexOf('_V.jpg') > 0){
			videoImageIndex = index;
		}
	}

	if (videoImageIndex == -1) {
		return;
	} else if(videoImageIndex === 0) {
		var content = '<div id="product-video-dialog"></div>' + imgContainer.eq(videoImageIndex).find('a').html();
		imgContainer.eq(videoImageIndex).find('a').remove();
		imgContainer.eq(videoImageIndex).html(content);
		imgContainer.eq(videoImageIndex).addClass('video-images');
	} else if(videoImageIndex === 1 || videoImageIndex ===2) {
		if (isMobile) {
			var content = '<div id="product-video-dialog"></div>' + imgContainer.eq(videoImageIndex).find('a').html();
			imgContainer.eq(videoImageIndex).find('a').remove();
			imgContainer.eq(videoImageIndex).html(content);
			imgContainer.eq(videoImageIndex).addClass('video-images');
		} else {
			videoImageIndex = videoImageIndex - 1;
			var content = '<div id="product-video-dialog"></div>' + imgContainer1.eq(videoImageIndex).find('a').html();
			imgContainer1.eq(videoImageIndex).find('a').remove();
			imgContainer1.eq(videoImageIndex).html(content);
			imgContainer1.eq(videoImageIndex).addClass('video-images');
		}
	} else if(videoImageIndex === 3 || videoImageIndex === 4 || videoImageIndex == 5) {
		if (isMobile) {
			var content = '<div id="product-video-dialog"></div>' + imgContainer.eq(videoImageIndex).find('a').html();
			imgContainer.eq(videoImageIndex).find('a').remove();
			imgContainer.eq(videoImageIndex).html(content);
			imgContainer.eq(videoImageIndex).addClass('video-images');
		} else {
			videoImageIndex = videoImageIndex - 3;
			var content = '<div id="product-video-dialog"></div>' + imgContainer2.eq(videoImageIndex).find('a').html();
			imgContainer2.eq(videoImageIndex).find('a').remove();
			imgContainer2.eq(videoImageIndex).html(content);
			imgContainer2.eq(videoImageIndex).addClass('video-images');
		}
	}

	var videoPlayer = null;
	var lastClickDate = null;
	function isContinuousClicks(event){
		var date = new Date();
		date = date.getTime();
		if (lastClickDate !== null && (date - lastClickDate) < 1000 && $(this).find('video').length == 0) {
			return true;
		}
		lastClickDate = date;
		return false;
	}

	$('.video-images').on('click', function() {
		if (isContinuousClicks() == true) {
			return ;
		}
		if ($(this).find('video').length > 0) {
			if(videoPlayer.getState() == 'PLAYING'){
				videoPlayer.play(false);
				$('#product-video-dialog_display_button').css('opacity', '1');
			} else {
				videoPlayer.play(true);
			}
			return ;
		}

		var videoUrl = $(this).find('img').attr('src');
		videoUrl = videoUrl.split('?')[0];
		videoUrl = videoUrl.substring(videoUrl.indexOf('/on/demandware.static/')).replace('.jpg', '.mp4');
		var width = $(this).find('img.img-product').css("width");
		var height = $(this).find('img.img-product').css("height");

		videoPlayer = jwplayer('product-video-dialog').setup({
				file: videoUrl,
				width: Number(width.substring(0, width.length - 2)),
				height: Number(height.substring(0, height.length - 2)),
				autostart: true,
				mute: true,
				controls: false
			});

		videoPlayer.onPlaylistComplete(function() {
			Maje.zoomProduct.bindEvents.showOrginalVideoImage();
		});

		$(this).find('img.img-product').css('display', 'none');
		$(this).find('img.img-product').css('opacity', '0');
		var eventID = setInterval(function() {
			if($('#product-video-dialog').length > 0 && $('#product-video-dialog_menu').length > 0) {
				clearInterval(eventID);
				$('#product-video-dialog_menu').remove();
			} else if($('#product-video-dialog').find('video').attr('src') == undefined) {
				$('#product-video-dialog').find('video').attr('src', videoUrl);
			}
		}, 500);

	});

	$('.video-images').on("touchstart", function() {
		if(videoPlayer){
			if ($(this).find('video').length > 0) {
				if(videoPlayer.getState() == 'PLAYING'){
					videoPlayer.play(false);
					$('#product-video-dialog_display_button').css('opacity', '1');
				} else {
					videoPlayer.play(true);
				}
				return ;
			}
		}
	});

	// Show original video image
	Maje.zoomProduct.bindEvents.showOrginalVideoImage = function  () {
		$('#product-video-dialog').empty();
		$('#product-video-dialog').css('display', 'none');
		$('#product-video-dialog').css('opacity', '0');

		$('.video-images').find('img.img-product').css('display', 'block');
		$('.video-images').find('img.img-product').css('opacity', '1');
	}

	// Stop playing when not visible
	window.addEventListener('scroll', function() {
		var pageScroll = getPageScroll();
		var videoDialogelement= $('#product-video-dialog')[0];
		if (videoDialogelement != undefined) {
			if ((pageScroll.y + window.screen.availHeight) > videoDialogelement.offsetTop != true
					||(videoDialogelement.offsetTop + videoDialogelement.offsetHeight) > pageScroll.y != true) {
				Maje.zoomProduct.bindEvents.showOrginalVideoImage();
			}
		}
	});
};

Maje.giftpage.filters = function () {

	$('.gift-page-categories').on('change', function() {
		var link = ($(this).find(":selected").data('link'));
		var style = $('.gift-page-style').find(":selected").val();
		var categorySelect = $(this).find(":selected").val();
		if(categorySelect == "" && style == ""){
			window.location = $('.no-filter').attr('href');
		} else {
			var data = style != null ? {format: 'ajax', prefn1 : 'maje_gift_styles',  prefv1 : style, isGiftPage : true} : {format: 'ajax', isGiftPage : true};
			app.progress.show($("#main").find(".search-result-content"));
			$.ajax({
				url : link,
				method: "GET",
				data: data,
				success : function(response) {
					$('.search-result-content').html(response);
					app.progress.hide();
				},
			});
		}
	});

	$('.gift-page-style').on('change', function() {
		var styleSelected = ($(this).find(":selected").val());
		var link = $('.gift-page-categories').find(":selected").data('link');
		var categorySelect = $('.gift-page-categories').find(":selected").val();
		if(categorySelect == "" && styleSelected == ""){
			window.location = $('.no-filter').attr('href');
		} else {
			var data = styleSelected != null ? {format: 'ajax', prefn1 : 'maje_gift_styles',  prefv1 : styleSelected, isGiftPage : true} : {format: 'ajax', isGiftPage : true};
			app.progress.show($("#main").find(".search-result-content"));
			 $.ajax({
					url : link,
					method: "GET",
					data: data,
					success : function(response) {
						$('.search-result-content').html(response);
						app.progress.hide();
					},
				});
		}

	});
};

Maje.giftpage.bindEvents = function(){
	Maje.giftpage.filters();
};

Maje.login.bindEvents = function(){
	var date  =  new Date();
	$('#button2').click(function(){
		var lp = $(this).closest('div').find("input[name$=password]").val();
		var remembermeStatus = $(this).closest('div').find("input[name$=rememberme]").attr("checked");
		if(remembermeStatus == 'checked'){
			date.setFullYear(9999,12,30);
			var cookieDate = date.toUTCString();
			document.cookie = "signPw="+lp+";expires= "+cookieDate+"";
		}else{
			document.cookie = "signPw=;expires=" + date.toGMTString();
		}
	});
	$('#connection').click(function(){
		var lp = $(this).closest('div').siblings('.login-box').find("input[name$=password]").val();
		var remembermeStatus = $(this).closest('div').siblings('.login-box').find("input[name$=rememberme]").attr("checked");
		if(remembermeStatus == 'checked'){
			date.setFullYear(9999,12,30);
			var cookieDate = date.toUTCString();
			document.cookie = "signPw="+lp+";expires= "+cookieDate+"";
		}else{
			document.cookie = "signPw=;expires=" + date.toGMTString();
		}
	});
	
	/*if($.cookie('signPw')) {
		$(".form-row").find("input[name$=rememberme]").attr("checked",true);
	};*/
	
};
Maje.login.bindEvents();

/**
 * open a dialog
 * popupContainer : Element
 * options : Object
 */
Maje.util.modal = function(popupContainer, options) {

	var options = (typeof options !== 'undefined') ? options : {};

	if(popupContainer.length === 0){
		return;
	}

	if($(window).width() < 1024){
		$(".cart-centred-container").fadeOut();
		popupContainer.fadeIn();
		return;
	}
	var dlgOptions = $.extend({}, app.dialog.settings, options);

	var dlg = app.dialog.create({target:popupContainer, options : dlgOptions});
	dlg.dialog("open");
	$('.ui-widget-overlay').off("click").on('click', function(){
		dlg.dialog("close");
	});
}

Maje.util.showLoginPopup = function() {
	Maje.util.modal($('#login_popin'), {
		dialogClass: "login-checkout primary-content login block-shadow"
	});
}

Maje.footer.showSizegrid = function(){
	$(document).on('click', 'a.sizeguide', function(){
		$("#sizeGrid").dialog({
			draggable: false,
			dialogClass : 'sizeGrid',
			width: ($(window).width() >= 768 ? 600 : "auto"),
			modal: true,
			closeText: app.resources.SIZEGRID_CLOSE_TXT,
			title: app.resources.SIZEGRID_TITLE_DIALOG,
		});
	}).on('click', '.close-size-guide, .ui-widget-overlay', function(){
		$('#sizeGrid').dialog("close");
	});
}

/*
This method is used in validator.js
This method exist and has the same name in Sandro.js to make an unique call in validator.js
*/
Common.validator.refreshCartAmounts = function(zipCodeValue) {

	Maje.cart.refreshCartAmounts(zipCodeValue);
}

/*
Sandro Einstein
Content Slot template : einstein_cartimpulse.isml
*/
Maje.einstein.bindEvents = function(){
	Maje.einstein.sliderCart();
	Maje.product.eventLookComplet();
}

/*
Init slider for Einstein
*/
Maje.einstein.sliderCart = function() {
/* init slider */
if($('.einstein .swiper-container').length){
	/*	We need to init slider ever time .completeLook .swiper-container2 is present
		Look Complet also uses this slider in the Product Page 	*/
	$('.einstein .swiper-container').each(function( i ) {
		var ensteinSwiper = $(this).swiper({
			slidesPerView: 4,
			calculateHeight: true
		});
		/* button next and prev */
		$(".einstein .arrow-left").on("click", function(e) {
			e.preventDefault();
			ensteinSwiper.swipePrev();
		});
		$(".einstein .arrow-right").on("click", function(e) {
			e.preventDefault();
			ensteinSwiper.swipeNext();
		});
	});
}
}